import { IContextClient } from 'api/types';

let contextClient: IContextClient;

export const setContextClient = (client: IContextClient): void => {
  contextClient = client;
};

export const getAccessToken = (): Promise<string | null> => {
  if (!contextClient?.getAccessToken) {
    throw new Error('Auth client not configured, please call configure with an auth client before using this method.');
  }

  return contextClient.getAccessToken();
};

export const getEmployeeUuid = (): Promise<string | undefined> => {
  if (!contextClient?.getEmployeeUuid) {
    throw new Error('Auth client not configured, please call configure with an auth client before using this method.');
  }

  return Promise.resolve(contextClient.getEmployeeUuid());
};
