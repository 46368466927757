import React from 'react';
import cx from 'classnames';
import { SemanticSIZES } from 'semantic-ui-react';

import Image from '../Image';
import { SkeletonImage } from '../Skeleton';
import Initials from './Initials';

export interface AvatarProps extends StrictAvatarProps {
  [key: string]: any;
}

export type StrictAvatarProps = Readonly<{
  /** Circular Avatar variant.  */
  circular: boolean;

  /** Additional classes */
  className?: string;

  /** Fallback Avatar in case image doesn't exist and name not available for initials */
  fallbackSource: string;

  /** An identifier used to ensure consistent color even if name changes. Fall back to name. */
  id?: string;

  /** The name that will be used to generate the initials  */
  name?: string;

  /** Should show placeholder while loading  */
  placeholder: boolean;

  /** Size of the Avatar. */
  size: SemanticSIZES;

  /** Source of the Avatar's Image */
  src: string | null;
}>;

interface State {
  error: boolean;
  isLoaded: boolean;
  source: string | null;
}

export default class Avatar extends React.Component<StrictAvatarProps, State> {
  static defaultProps = {
    placeholder: true,
    size: 'medium',
    fallbackSource: null,
    circular: false,
  };

  state = {
    error: false,
    isLoaded: false,
    source: '',
  };

  static getDerivedStateFromProps({ src }: AvatarProps, state: State): State | null | undefined {
    if (src !== state.source) {
      return {
        error: false,
        isLoaded: false,
        source: src,
      };
    }
    return null;
  }

  setError = () => this.setState({ error: true });

  setHasLoaded = () => this.setState({ isLoaded: true });

  render() {
    const { placeholder, fallbackSource, name, src, ...initialsProps } = this.props;
    const { className, size, circular } = initialsProps;
    const { source, error, isLoaded } = this.state;
    const failedToLoad = error || !source;
    const showPlaceholder = placeholder && !isLoaded && !failedToLoad;

    if (failedToLoad) {
      if (name !== undefined) {
        return <Initials name={name} {...initialsProps} />;
      }
      if (!fallbackSource) {
        return <SkeletonImage size={size} circular={circular} animated={false} />;
      }
    }
    return (
      <>
        {showPlaceholder && <SkeletonImage size={size} circular={circular} />}
        <Image
          rounded
          {...initialsProps}
          className={cx(className, { hidden: showPlaceholder })}
          onError={this.setError}
          onLoad={this.setHasLoaded}
          src={failedToLoad ? fallbackSource : source}
        />
      </>
    );
  }
}
