export default {
    version: 8,
    name: 'WeWork Default',
    metadata: {
        'mapbox:autocomposite': false,
        'mapbox:type': 'template',
        'mapbox:sdk-support': {
            js: '0.54.0',
            android: '7.4.0',
            ios: '4.11.0'
        },
        'mapbox:groups': {},
        'mapbox:uiParadigm': 'layers',
        'mapbox:trackposition': false
    },
    center: [0.0002283879715605508, 0.00013831753844328887],
    zoom: 19.432330297085862,
    bearing: 0,
    pitch: 5,
    light: {
        intensity: 0.1
    },
    sources: {
        composite: {
            url: 'mapbox://telkowework.ck18a32sk0fer2oo2u9us5ilr-2qc1o,telkowework.ck18abaqr1uu82npk29n6gntf-5pu8z,telkowework.ck18adhy009x22im84gqp0m2f-1rtb3,telkowework.ck55ur7f30ya52nqm38jy5au8-3llpf,telkowework.ck560d0x601252ipfy4a86fp8-1zw0v',
            type: 'vector'
        },
        'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-9rpms': {
            url: 'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-9rpms',
            type: 'vector'
        },
        'mapbox://telkowework.ck18abaqr1uu82npk29n6gntf-7ol5j': {
            url: 'mapbox://telkowework.ck18abaqr1uu82npk29n6gntf-7ol5j',
            type: 'vector'
        },
        'mapbox://telkowework.ck18abaqr1uu82npk29n6gntf-7pn21': {
            url: 'mapbox://telkowework.ck18abaqr1uu82npk29n6gntf-7pn21',
            type: 'vector'
        },
        'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-1r41w': {
            url: 'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-1r41w',
            type: 'vector'
        },
        'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-517ws': {
            url: 'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-517ws',
            type: 'vector'
        },
        'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-0gvxx': {
            url: 'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-0gvxx',
            type: 'vector'
        },
        'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-63bpz': {
            url: 'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-63bpz',
            type: 'vector'
        }
    },
    sprite: 'mapbox://sprites/telkowework/ckp31wxbv6z9017ozgnis42fq/draft',
    glyphs: 'mapbox://fonts/telkowework/{fontstack}/{range}.pbf',
    layers: [
        {
            id: 'background',
            type: 'background',
            paint: { 'background-color': 'white', 'background-opacity': 0 }
        },
        {
            id: 'WSAnnotationPolygonsFillUnderLabels',
            type: 'fill',
            source: 'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-9rpms',
            'source-layer': 'Annotations5',
            filter: ['all', ['==', ['get', 'show3DAnnotation'], null]],
            paint: {
                'fill-color': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    ['case', ['has', 'isSelected'], ['get', 'selectedFillColor'], ['get', 'hoverFillColor']],
                    ['case', ['has', 'isSelected'], ['get', 'selectedFillColor'], ['get', 'fillColor']]
                ],
                'fill-opacity': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    ['case', ['has', 'isSelected'], ['get', 'selectedFillOpacity'], ['get', 'hoverFillOpacity']],
                    ['case', ['has', 'isSelected'], ['get', 'selectedFillOpacity'], ['get', 'fillOpacity']]
                ],
                'fill-outline-color': 'hsla(0, 0%, 0%, 0)'
            }
        },
        {
            id: 'WSAnnotationPolygonOutlinesUnderLabels',
            type: 'line',
            source: 'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-9rpms',
            'source-layer': 'Annotations5',
            filter: ['all', ['has', 'underLabels'], ['has', 'borderColor'], ['==', ['get', 'show3DAnnotation'], null]],
            layout: { 'line-cap': 'round', 'line-join': 'round' },
            paint: {
                'line-color': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderColor'], ['get', 'hoverBorderColor']],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderColor'], ['get', 'borderColor']]
                ],
                'line-opacity': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderOpacity'], ['get', 'hoverBorderOpacity']],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderOpacity'], ['get', 'borderOpacity']]
                ],
                'line-width': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderWidth'], ['get', 'hoverBorderWidth']],
                    ['case', ['has', 'isSelected'], ['get', 'selectedBorderWidth'], ['get', 'borderWidth']]
                ]
            }
        },
        {
            id: 'WSAnnotation3DPolygons',
            type: 'fill-extrusion',
            source: 'composite',
            'source-layer': 'Annotations5',
            filter: ['all', ['!=', ['get', 'show3DAnnotation'], null]],
            paint: {
                'fill-extrusion-color': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    ['case', ['has', 'isSelected'], ['get', 'selectedFillColor'], ['get', 'hoverFillColor']],
                    ['case', ['has', 'isSelected'], ['get', 'selectedFillColor'], ['get', 'fillColor']]
                ],
                'fill-extrusion-vertical-gradient': true,
                'fill-extrusion-translate-anchor': 'viewport',
                'fill-extrusion-base': ['get', 'fillExtrusionBase'],
                'fill-extrusion-opacity': 1,
                'fill-extrusion-height': ['get', 'fillExtrusionHeight']
            }
        },
        {
            id: 'WSAnnotationPolygonsFillPattern',
            type: 'fill',
            source: 'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-9rpms',
            'source-layer': 'Annotations5',
            paint: {
                'fill-pattern': [
                    'case',
                    ['boolean', ['has', 'isHovered'], false],
                    ['case', ['has', 'isSelected'], ['get', 'selectedFillPattern'], ['get', 'hoverFillPattern']],
                    ['case', ['has', 'isSelected'], ['get', 'selectedFillPattern'], ['get', 'fillPattern']]
                ]
            }
        },
        {
            id: 'WSAnnotationLabels',
            type: 'symbol',
            source: 'mapbox://telkowework.ckk4ggu341mhq20lcaxsmr21i-1r41w',
            'source-layer': 'Annotations7',
            layout: {
                'text-line-height': 1,
                'text-size': ['interpolate', ['exponential', 1.96], ['zoom'], 0, 0, 21, 12],
                'text-radial-offset': 0.8,
                'text-allow-overlap': true,
                'icon-offset': ['case', ['==', ['get', 'label'], ''], ['literal', [0, 0]], ['literal', [0, -10]]],
                'icon-image': ['get', 'icon'],
                'icon-allow-overlap': true,
                'text-font': ['Apercu WeWork Mono Bold'],
                'icon-size': ['interpolate', ['exponential', 1.96], ['zoom'], 0, 0, 21, ['get', 'iconSize']],
                'text-anchor': ['case', ['has', 'icon'], 'top', 'center'],
                'text-field': ['get', 'label']
            }
        }
    ],
    created: '2021-05-24T20:17:34.672Z',
    modified: '2022-04-27T21:46:52.283Z',
    id: 'ckp31wxbv6z9017ozgnis42fq',
    owner: 'telkowework',
    visibility: 'private',
    protected: false,
    draft: true
}
