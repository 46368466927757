import React from 'react';
import cn from 'classnames';
// @ts-ignore
import { getElementType } from 'semantic-ui-react/dist/commonjs/lib';

import Text, { StrictTextProps } from '../Text';

export interface StrictMessageActionProps extends StrictTextProps {
  label: React.ReactNode;
  handler: (event: React.SyntheticEvent<HTMLElement>) => void;
}

export interface MessageActionProps extends StrictMessageActionProps {
  [key: string]: any;
}

export const MessageAction: React.FC<MessageActionProps> = (props) => {
  const { className, handler, label, ...rest } = props;
  const ElementType = getElementType(MessageAction, props);

  return (
    <Text className={cn(className, 'action')} as={ElementType} onClick={handler} {...rest}>
      {label}
    </Text>
  );
};

MessageAction.displayName = 'MessageAction';

export default MessageAction;
