import React from 'react';
import cx from 'classnames';
import { SemanticSIZES, SemanticCOLORS } from 'semantic-ui-react';
// @ts-ignore
import { getElementType } from 'semantic-ui-react/dist/commonjs/lib';

export const COLORS: Array<SemanticCOLORS> = [
  'orange',
  'yellow',
  'olive',
  'green',
  'teal',
  'blue',
  'violet',
  'purple',
  'pink',
  'red',
];

const getInitialsForName = (name: string) => {
  const matchedNames = name.match(/\b(\w).+\b(\w)/);
  if (matchedNames) {
    return matchedNames[1] + matchedNames[2];
  }
  const oneName = name.match(/\b(\w)/);
  if (oneName) {
    return oneName[1];
  }
  return null;
};

const calculateHash = (str: string) => {
  let hash = 5381;
  let i = str.length;

  while (i) {
    // eslint-disable-next-line no-bitwise, no-plusplus
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }

  /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
   * integers. Since we want the results to be always positive, convert the
   * signed int to an unsigned by doing an unsigned bitshift. */
  // eslint-disable-next-line no-bitwise
  return hash >>> 0;
};

export interface Props {
  /** An element type to render as (string or function). */
  as?: any;

  /** Circular initials variant.  */
  circular?: boolean;

  /** Additional classes */
  className?: string;

  /** Override color selection. */
  color?: SemanticCOLORS;

  /** Override for initials picker */
  getInitialsForName?: (name: string) => string;

  /** An identifier used to ensure consistent color even if name changes. Fall back to name. */
  id?: string;

  /** The name that will be used to generate the initials  */
  name: string;

  /** Size of the Initials, aligned with Image sizes */
  size: SemanticSIZES;
}

export default class Initials extends React.Component<Props> {
  getColor() {
    const { id, name, color } = this.props;
    if (color) {
      return color;
    }
    return COLORS[calculateHash(id || name) % COLORS.length];
  }

  getInitials() {
    const { name } = this.props;
    if (this.props.getInitialsForName) {
      return this.props.getInitialsForName(name);
    }
    return getInitialsForName(name);
  }

  render() {
    const { size, className, circular } = this.props;
    const ElementType = getElementType(Initials, this.props);

    return (
      <ElementType className={cx('ui rounded image initials', this.getColor(), size, className, { circular })}>
        {this.getInitials()}
      </ElementType>
    );
  }
}
