import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { Message as SemanticMessage, StrictMessageProps as SemanticStrictMessageProps } from 'semantic-ui-react';
import cn from 'classnames';

import MessageAction, { StrictMessageActionProps } from './MessageAction';

export { MessageSizeProp } from 'semantic-ui-react';

export type MessageActionProp = Pick<StrictMessageActionProps, 'label' | 'handler'>;

export interface StrictMessageProps extends SemanticStrictMessageProps {
  /** Adds an action textual button with given label as text and given handler as onClick. */
  action?: MessageActionProp;
}

export interface MessageProps extends StrictMessageProps {
  [key: string]: any;
}

interface ExtendedMessageComponent extends React.FunctionComponent<MessageProps> {
  Action: typeof MessageAction;
}

const ExtendedMessage: ExtendedMessageComponent = ({ action, children, className, ...rest }) => (
  <SemanticMessage
    className={cn(className, {
      action,
    })}
    {...rest}
  >
    {children && (
      <>
        {action ? <Message.Content>{children}</Message.Content> : children}
        {action ? <MessageAction label={action.label} handler={action.handler} /> : null}
      </>
    )}
  </SemanticMessage>
);

ExtendedMessage.Action = MessageAction;

export const Message: typeof ExtendedMessage &
  hoistNonReactStatic.NonReactStatics<typeof SemanticMessage> = hoistNonReactStatic(ExtendedMessage, SemanticMessage);

Message.displayName = 'Message';

export default Message;
