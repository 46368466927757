import { Layer, Style } from 'maplibre-gl'
import { FeatureType } from './SpaceData'

export function formatStyle(style: Style, floorPlanServiceURL: string): Style {
    // swap layer sources
    style.layers?.forEach((layer: Layer) => {
        const newSource = Object.values(FeatureType).filter((type) => layer?.id?.toLowerCase().includes(type))[0]
        if (newSource) {
            layer.source = newSource
            delete layer['source-layer']
        }
    })

    // add local (empty) geojson sources
    style.sources = {}
    for (const type of Object.values(FeatureType)) {
        style.sources[type] = {
            type: 'geojson',
            data: { type: 'FeatureCollection', features: [] }
        }
    }

    // fonts and glyphs hosted by floor plan service https://github.com/WeConnect/floor-plan-service.
    style.glyphs = floorPlanServiceURL + '/floor-plan-sdk-public/fonts/{fontstack}/{range}.pbf'
    style.sprite = floorPlanServiceURL + '/floor-plan-sdk-public/sprites/sprite'

    delete style.metadata

    return style
}
