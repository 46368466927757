import React from 'react';
import cn from 'classnames';
import DayPicker, { DayPickerProps as DatePickerProps, ClassNames, RangeModifier, Modifier } from 'react-day-picker';

export { LocaleUtils, DateUtils } from 'react-day-picker';

export {
  DayPickerProps as DatePickerProps,
  ClassNames,
  DayModifiers,
  RangeModifier,
  BeforeModifier,
  AfterModifier,
  BeforeAfterModifier,
  DaysOfWeekModifier,
  FunctionModifier,
  Modifier,
  Modifiers,
} from 'react-day-picker';

export const classNames: ClassNames = {
  container: 'ui datepicker',
  wrapper: 'wrapper',
  interactionDisabled: 'disabled',
  navBar: 'navbar',
  navButtonPrev: 'ui button basic primary circular prev',
  navButtonNext: 'ui button basic primary circular next',
  navButtonInteractionDisabled: 'disabled',

  months: 'months',
  month: 'month',
  caption: 'caption',
  weekdays: 'weekdays',
  weekdaysRow: 'row',
  weekday: 'weekday',
  weekNumber: 'week-number',
  body: 'body',
  week: 'week',
  day: 'day',
  footer: 'footer',
  todayButton: 'button today',

  today: 'today',
  selected: 'selected',
  disabled: 'disabled',
  outside: 'outside',
};

export const defaultProps: DatePickerProps = {
  classNames,
  renderDay(date: Date) {
    const day = date.getDate();

    if (day < 10) {
      return `0${day}`;
    }

    return day;
  },
};

function isRangeModifier(modifier: Modifier): modifier is RangeModifier {
  return !!modifier && 'from' in modifier && 'to' in modifier;
}

export const DatePicker: React.FC<DatePickerProps> = (props) => {
  const className = cn(props.className, {
    range: Array.isArray(props.selectedDays)
      ? props.selectedDays.some((v) => isRangeModifier(v))
      : isRangeModifier(props.selectedDays),
  });

  return <DayPicker {...defaultProps} {...props} className={className} />;
};

DatePicker.displayName = 'DatePicker';

export default DatePicker;
