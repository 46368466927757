import { Feature, Geometry, LineString, Point, Polygon } from 'geojson'
import { FloorPlanFeatureProperties, Projection } from './FloorPlanToGeoJsonConverter.interfaces'
import { PolygonAnnotation, PointAnnotation, LineAnnotation } from '../FloorPlan.public.interfaces'
import { srsPointFromArray, WeSpaceProjection } from './WeSpaceProjection'
import { Annotation } from '../FloorPlan.public.interfaces'
import { fastHash } from '../snapping/helpers'

export function convertAnnotationsToGeoJSON(
    annotations: Array<PolygonAnnotation | PointAnnotation | LineAnnotation>,
    projection: Projection = new WeSpaceProjection()
): Feature<Geometry, FloorPlanFeatureProperties>[] {
    return annotations.map((a) => {
        if (isPointAnnotation(a as Annotation)) {
            return featureForPointAnnotation(a as PointAnnotation, projection)
        } else if (isLineAnnotation(a as Annotation)) {
            return featureForLineAnnotation(a as LineAnnotation, projection)
        } else {
            return featureForPolygonAnnotation(a as PolygonAnnotation, projection)
        }
    })
}

export function isPointAnnotation(annotation: Annotation): annotation is PointAnnotation {
    return annotation?.geometry?.type === 'Point'
}

export function isLineAnnotation(annotation: Annotation): annotation is LineAnnotation {
    return annotation?.geometry?.type === 'LineString'
}

export function isPolygonAnnotation(annotation: Annotation): annotation is PolygonAnnotation {
    return annotation?.geometry?.type === 'Polygon'
}

function featureForPointAnnotation(
    annotation: PointAnnotation,
    projection: Projection = new WeSpaceProjection()
): Feature<Point, FloorPlanFeatureProperties> {
    const feature: Feature<Point, FloorPlanFeatureProperties> = {
        id: annotation.id,
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: projection.unProject(srsPointFromArray(annotation.geometry.coordinates))
        },
        properties: {
            id: annotation.id
        }
    }
    // If any rotation value is provided, use WSAnnotationRotatedImages layer to render icons with rotation fixed to the map (not user camera).
    if (
        annotation.properties?.style?.rotationDegrees != undefined ||
        annotation.properties?.selectedStyle?.rotationDegrees != undefined ||
        annotation.properties?.hoverStyle?.rotationDegrees != undefined
    ) {
        // Pass icon in *RImage properties to render in WSAnnotationRotatedImages layer instead of WSAnnotationImages layer
        // WSAnnotationRotatedImages fixes icon rotation to the map. WSAnnotationImages fixes icon rotation to the user camera (always up).
        if (annotation.properties?.style?.icon) {
            feature.properties.rImage = fastHash(annotation.properties.style.icon).toString()
            feature.properties.selectedRImage = fastHash(annotation.properties.style.icon).toString()
            feature.properties.hoverRImage = fastHash(annotation.properties.style.icon).toString()
        }
        if (annotation.properties?.selectedStyle?.icon)
            feature.properties.selectedRImage = fastHash(annotation.properties.selectedStyle.icon).toString()
        if (annotation.properties?.hoverStyle?.icon)
            feature.properties.hoverRImage = fastHash(annotation.properties.hoverStyle.icon).toString()

        // rotation
        if (annotation.properties?.style?.rotationDegrees) {
            feature.properties.rotation = annotation.properties?.style?.rotationDegrees
            feature.properties.selectedRotation = annotation.properties?.style?.rotationDegrees
            feature.properties.hoverRotation = annotation.properties?.style?.rotationDegrees
        }
        if (annotation.properties?.selectedStyle?.rotationDegrees)
            feature.properties.selectedRotation = annotation.properties?.selectedStyle?.rotationDegrees
        if (annotation.properties?.hoverStyle?.rotationDegrees)
            feature.properties.hoverRotation = annotation.properties?.hoverStyle?.rotationDegrees
    } else {
        // If no rotation is provided, let icons float above the map in an "always up" position
        if (annotation.properties?.style?.icon) {
            feature.properties.image = fastHash(annotation.properties.style.icon).toString()
            feature.properties.selectedImage = fastHash(annotation.properties.style.icon).toString()
            feature.properties.hoverImage = fastHash(annotation.properties.style.icon).toString()
        }
        if (annotation.properties?.selectedStyle?.icon)
            feature.properties.selectedImage = fastHash(annotation.properties.selectedStyle.icon).toString()
        if (annotation.properties?.hoverStyle?.icon)
            feature.properties.hoverImage = fastHash(annotation.properties.hoverStyle.icon).toString()
    }

    if (annotation.properties?.underRoomLabels === true) feature.properties['underLabels'] = 'true'

    return feature
}

function featureForLineAnnotation(
    annotation: LineAnnotation,
    projection: Projection = new WeSpaceProjection()
): Feature<LineString, FloorPlanFeatureProperties> {
    return {
        id: annotation.id,
        type: 'Feature',
        geometry: {
            type: 'LineString',
            coordinates: annotation.geometry.coordinates.map((p) => projection.unProject(srsPointFromArray(p)))
        },
        properties: {
            id: annotation.id,
            // default styles
            borderColor: annotation.properties?.style?.line?.color,
            borderOpacity: annotation.properties?.style?.line?.opacity,
            borderWidth: annotation.properties?.style?.line?.width,
            // hover styles
            hoverBorderColor:
                annotation?.properties?.hoverStyle?.line?.color || annotation.properties?.style?.line?.color,
            hoverBorderOpacity:
                annotation.properties?.hoverStyle?.line?.opacity || annotation.properties?.style?.line?.opacity,
            hoverBorderWidth:
                annotation.properties?.hoverStyle?.line?.width || annotation.properties?.style?.line?.width,
            // selected styles:
            selectedBorderColor:
                annotation.properties?.selectedStyle?.line?.color || annotation.properties?.style?.line?.color,
            selectedBorderOpacity:
                annotation.properties?.selectedStyle?.line?.opacity || annotation.properties?.style?.line?.opacity,
            selectedBorderWidth:
                annotation.properties?.selectedStyle?.line?.width || annotation.properties?.style?.line?.width,
            line: true
        }
    }
}

function featureForPolygonAnnotation(
    annotation: PolygonAnnotation,
    projection: Projection = new WeSpaceProjection()
): Feature<Polygon, FloorPlanFeatureProperties> {
    const feature: Feature<Polygon, FloorPlanFeatureProperties> = {
        id: annotation.id,
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: annotation?.geometry?.coordinates?.map((pts) =>
                pts.map((p) => projection.unProject(srsPointFromArray(p)))
            )
        },
        properties: {
            label: annotation?.properties?.label,
            id: annotation.id,
            // default styles
            fillColor: annotation?.properties?.style?.fill?.color,
            fillOpacity: annotation?.properties?.style?.fill?.opacity,
            borderColor: annotation?.properties?.style?.line?.color,
            borderOpacity: annotation?.properties?.style?.line?.opacity,
            borderWidth: annotation?.properties?.style?.line?.width,
            // hover styles
            hoverFillColor:
                annotation?.properties?.hoverStyle?.fill?.color || annotation.properties?.style?.fill?.color,
            hoverFillOpacity:
                annotation.properties?.hoverStyle?.fill?.opacity || annotation.properties?.style?.fill?.opacity,
            hoverBorderColor:
                annotation?.properties?.hoverStyle?.line?.color || annotation.properties?.style?.line?.color,
            hoverBorderOpacity:
                annotation.properties?.hoverStyle?.line?.opacity || annotation.properties?.style?.line?.opacity,
            hoverBorderWidth:
                annotation.properties?.hoverStyle?.line?.width || annotation.properties?.style?.line?.width,
            // selected styles:
            selectedFillColor:
                annotation.properties?.selectedStyle?.fill?.color || annotation.properties?.style?.fill?.color,
            selectedFillOpacity:
                annotation.properties?.selectedStyle?.fill?.opacity || annotation.properties?.style?.fill?.opacity,
            selectedBorderColor:
                annotation.properties?.selectedStyle?.line?.color || annotation.properties?.style?.line?.color,
            selectedBorderOpacity:
                annotation.properties?.selectedStyle?.line?.opacity || annotation.properties?.style?.line?.opacity,
            selectedBorderWidth:
                annotation.properties?.selectedStyle?.line?.width || annotation.properties?.style?.line?.width,
            polygon: true
        }
    }
    if (annotation.properties?.underRoomLabels === true) feature.properties['underLabels'] = 'true'
    if (annotation.properties?.label?.length || 0 > 0) feature.properties['label'] = annotation.properties.label
    if (!!annotation.properties?.style?.icon) {
        feature.properties['icon'] = fastHash(annotation.properties?.style?.icon).toString()
        feature.properties['iconSize'] = annotation.properties?.style?.iconSize || 1
    }

    // for INVENTORY_MANAGER style. Shows a 3D polygon annotation
    feature.properties['show3DAnnotation'] = annotation?.properties?.show3DAnnotation || null
    feature.properties['fillExtrusionHeight'] = annotation?.properties?.fillExtrusionHeight || 0
    feature.properties['fillExtrusionBase'] = annotation?.properties?.fillExtrusionBase || 0

    // fill patterns
    if (annotation.properties?.style?.fill?.pattern) {
        feature.properties['fillPattern'] = annotation.properties.style.fill.pattern
        feature.properties['selectedFillPattern'] =
            annotation?.properties?.selectedStyle?.fill?.pattern || annotation.properties.style.fill.pattern
        feature.properties['hoverFillPattern'] =
            annotation?.properties?.hoverStyle?.fill?.pattern || annotation.properties.style.fill.pattern
    }
    return feature
}
