import { stringify } from 'query-string';

import { makeSimpleCache, SimpleCacheMap, SimpleCacheType } from 'caching/simpleCache';
import { getAccessToken, getEmployeeUuid } from 'api/authClient';
import { OperatorServicePermissionsMap, OperatorServiceRolesMap } from 'api/types';
import { getEnvironment } from 'config';
import { cachedFetch } from 'api/fetch';

const OPERATOR_SERVICE_URIS = {
  test: 'https://operator-service.test',
  development: 'https://operator-service.staging.weworkers.io',
  staging: 'https://operator-service.staging.weworkers.io',
  production: 'https://operator-service.weworkers.io',
};

export const getOperatorServiceUri = (): string => {
  const environment = getEnvironment();
  return OPERATOR_SERVICE_URIS[environment];
};

type FetchFunction = (employeeUuid: string, accessToken: string, keysToFetch: string[]) => Promise<Response>;

export const ANY_ACCUMULATOR = 'ANY';

// exported for testing
export const cachedRoles = makeSimpleCache<boolean>();
export const cachedPermissions = makeSimpleCache<boolean>();
export const cachedRolesByUser = makeSimpleCache<boolean>();
export const cachedPermissionsByUser = makeSimpleCache<boolean>();
export const cachedRolesByAccount = makeSimpleCache<boolean>();
export const cachedPermissionsByAccount = makeSimpleCache<boolean>();

export type OperatorServiceErrorResponse = {
  message: string;
  fieldErrors?: Array<{
    field: string;
    message: string;
  }>;
};

export class OperatorServiceError extends Error {
  public static ERROR_MESSAGE = 'Failed retrieving data from OperatorService.';

  public data: OperatorServiceErrorResponse;
  public response: Response;

  constructor(
    data: OperatorServiceErrorResponse,
    response: Response,
    message: string = OperatorServiceError.ERROR_MESSAGE,
  ) {
    super(message);

    this.data = data;
    this.response = response;

    Object.setPrototypeOf(this, OperatorServiceError.prototype);
  }
}

async function makeCachedByEntityRequest<T>(
  entityUuid: string,
  keys: string[],
  fetchFn: FetchFunction,
  cache: SimpleCacheType<T>,
): Promise<SimpleCacheMap<T>> {
  const buildCacheKey = (key: string): string => `${entityUuid}-${key}`;
  const cached = keys.reduce((acc, key) => {
    const cacheKey = buildCacheKey(key);
    const cacheEntry = cache.getCachedEntry(cacheKey);
    if (cache.getCachedEntry(cacheKey) !== undefined) {
      acc[key] = cacheEntry;
    }
    return acc;
  }, {});
  const cachedKeys = Object.keys(cached);
  const keysToFetch = keys.filter((key) => !cachedKeys.includes(key));
  if (keysToFetch.length === 0) {
    return cached;
  }

  const employeeUuid = await getEmployeeUuid();
  const accessToken = await getAccessToken();

  // skip fetching if there is no authenticated user or we fail to get an access token
  if (!employeeUuid || !accessToken) {
    return keys.reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});
  }

  const response = await fetchFn(employeeUuid, accessToken, keysToFetch);

  // we use response.clone() here to ensure that we get a readable stream to .json() since the
  // promise for this fetch may have been cached and re-used by cachedFetch()
  const newData = await response.clone().json();
  if (response.status === 200) {
    const newCacheEntries = Object.entries(newData as SimpleCacheMap<T>).reduce(
      (acc: SimpleCacheMap<T>, entry: [string, T]) => {
        const [key, value] = entry;
        const cacheKey = buildCacheKey(key);
        acc[cacheKey] = value;
        return acc;
      },
      {},
    );

    cache.setCachedEntries(newCacheEntries);
    return {
      ...cached,
      ...newData,
    };
  } else {
    throw new OperatorServiceError(newData as OperatorServiceErrorResponse, response);
  }
}

export const currentEmployeeHasRoles = (roles: string[]): Promise<OperatorServiceRolesMap> => {
  const fetchFn = (employeeUuid: string, accessToken: string, keysToFetch: string[]): Promise<Response> => {
    const query = stringify({
      roles: keysToFetch.sort().join(','),
      employee_uuid: employeeUuid,
    });
    return cachedFetch(`${getOperatorServiceUri()}/v1/authz/has_roles?${query}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };

  return makeCachedByEntityRequest('', roles, fetchFn, cachedRoles);
};

export const currentEmployeeHasRolesByAnyUserLocation = (
  userUuid: string,
  roles: string[],
): Promise<OperatorServiceRolesMap> => {
  const fetchFn = (employeeUuid: string, accessToken: string, keysToFetch: string[]): Promise<Response> => {
    const query = stringify({
      roles: keysToFetch.sort().join(','),
      user_uuid: userUuid,
      employee_uuid: employeeUuid,
      accumulator: ANY_ACCUMULATOR,
    });
    return cachedFetch(`${getOperatorServiceUri()}/v1/authz/has_roles/by_user_spaceman_locations?${query}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };

  return makeCachedByEntityRequest(userUuid, roles, fetchFn, cachedRolesByUser);
};

export const currentEmployeeHasRolesByAnyAccountLocation = (
  accountUuid: string,
  roles: string[],
): Promise<OperatorServiceRolesMap> => {
  const fetchFn = (employeeUuid: string, accessToken: string, keysToFetch: string[]): Promise<Response> => {
    const query = stringify({
      roles: keysToFetch.sort().join(','),
      account_uuid: accountUuid,
      employee_uuid: employeeUuid,
      accumulator: ANY_ACCUMULATOR,
    });
    return cachedFetch(`${getOperatorServiceUri()}/v1/authz/has_roles/by_account_spaceman_locations?${query}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };

  return makeCachedByEntityRequest(accountUuid, roles, fetchFn, cachedRolesByAccount);
};

export const currentEmployeeHasPermissions = (permissions: string[]): Promise<OperatorServicePermissionsMap> => {
  const fetchFn = (employeeUuid: string, accessToken: string, keysToFetch: string[]): Promise<Response> => {
    const query = stringify({
      perms: keysToFetch.sort().join(','),
      employee_uuid: employeeUuid,
    });
    return cachedFetch(`${getOperatorServiceUri()}/v1/authz/has_permissions?${query}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };

  return makeCachedByEntityRequest('', permissions, fetchFn, cachedPermissions);
};

export const currentEmployeeHasPermissionsByAnyUserLocation = (
  userUuid: string,
  permissions: string[],
): Promise<OperatorServicePermissionsMap> => {
  const fetchFn = (employeeUuid: string, accessToken: string, keysToFetch: string[]): Promise<Response> => {
    const query = stringify({
      perms: keysToFetch.sort().join(','),
      user_uuid: userUuid,
      employee_uuid: employeeUuid,
      accumulator: ANY_ACCUMULATOR,
    });
    return cachedFetch(`${getOperatorServiceUri()}/v1/authz/has_permissions/by_user_spaceman_locations?${query}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };

  return makeCachedByEntityRequest(userUuid, permissions, fetchFn, cachedPermissionsByUser);
};

export const currentEmployeeHasPermissionsByAnyAccountLocation = (
  accountUuid: string,
  permissions: string[],
): Promise<OperatorServicePermissionsMap> => {
  const fetchFn = (employeeUuid: string, accessToken: string, keysToFetch: string[]): Promise<Response> => {
    const query = stringify({
      perms: keysToFetch.sort().join(','),
      account_uuid: accountUuid,
      employee_uuid: employeeUuid,
      accumulator: ANY_ACCUMULATOR,
    });
    return cachedFetch(`${getOperatorServiceUri()}/v1/authz/has_permissions/by_account_spaceman_locations?${query}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  };

  return makeCachedByEntityRequest(accountUuid, permissions, fetchFn, cachedPermissionsByAccount);
};
