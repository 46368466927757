import React from 'react';
import { TableHeaderCell as SemanticTableHeaderCell, TableHeaderCellProps } from 'semantic-ui-react';

import Icon from '../Icon';

const getSortedIconName = (sorted: string | undefined) => {
  if (!sorted) {
    return null;
  }

  return sorted === 'ascending' ? 'sort up' : 'sort down';
};

const TableHeaderCell: React.FC<TableHeaderCellProps> = ({ icon, sorted, children, content, ...props }) => {
  const cellContent = children ?? content;
  const sortIconName = getSortedIconName(sorted);
  return (
    <SemanticTableHeaderCell icon={icon} {...props}>
      {sortIconName ? (
        <div className="sorted-table-header">
          {cellContent}
          <Icon name={sortIconName} size="small" />
        </div>
      ) : (
        cellContent
      )}
    </SemanticTableHeaderCell>
  );
};

export { TableHeaderCellProps, StrictTableHeaderCellProps } from 'semantic-ui-react';

export default TableHeaderCell;
