export function distance(a: number[], b: number[]): number {
    return Math.sqrt(Math.pow(a[0] - b[0], 2) + Math.pow(a[1] - b[1], 2))
}

export function rotateCoord(coord: number[], center: number[], radians: number): number[] {
    if (radians == 0 || radians == Math.PI * 2) return coord
    const cos = Math.cos(radians)
    const sin = Math.sin(radians)
    const nx = cos * (coord[0] - center[0]) + sin * (coord[1] - center[1]) + center[0]
    const ny = cos * (coord[1] - center[1]) - sin * (coord[0] - center[0]) + center[1]
    return [nx, ny]
}

export function translateCoord(coord: number[], translation: number[]): number[] {
    return [coord[0] + translation[0], coord[1] + translation[1]]
}

/**
Fast, simple, insecure hash
 */
export function fastHash(s: string): number {
    let h = 0
    for (let i = 0; i < s.length; i++) {
        h = (Math.imul(31, h) + s.charCodeAt(i)) | 0
    }
    return h
}
