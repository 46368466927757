export interface IContextClient {
  getEmployeeUuid: () => string;
  getAccessToken: () => Promise<string | null>;
}

// A collection of known permissions. No longer used for Typescript checks.
export enum EmployeePermissions {
  activate_company = 'activate_company',
  active_member_keycard = 'active_member_keycard',
  add_enterprise_product = 'add_enterprise_product',
  add_global_access = 'add_global_access',
  authorize_illegal_membership_changes_and_moveouts = 'authorize_illegal_membership_changes_and_moveouts',
  cancel_reservation = 'cancel_reservation',
  create_commons_discount_code = 'create_commons_discount_code',
  create_self_executing_agreement = 'create_self_executing_agreement',
  disable_user = 'disable_user',
  edit_company_memberships = 'edit_company_memberships',
  edit_future_conference_room_reservations = 'edit_future_conference_room_reservations',
  edit_member_info = 'edit_member_info',
  edit_move_out = 'edit_move_out',
  enterprise_upload_manual_contracts = 'enterprise_upload_manual_contracts',
  id_scim_manage_sso_configs = 'id_scim_manage_sso_configs',
  manual_move_in = 'manual_move_in',
  manual_move_out = 'manual_move_out',
  opsrv_manage_employees = 'opsrv_manage_employees',
  opsrv_manage_global_roles = 'opsrv_manage_global_roles',
  opsrv_manage_locations = 'opsrv_manage_locations',
  opsrv_manage_operators = 'opsrv_manage_operators',
  opsrv_manage_operator_roles = 'opsrv_manage_operator_roles',
  opsrv_manage_roles_and_permissions = 'opsrv_manage_roles_and_permissions',
  opsrv_view_employees = 'opsrv_view_employees',
  opsrv_view_global_roles = 'opsrv_view_global_roles',
  opsrv_view_locations = 'opsrv_view_locations',
  opsrv_view_operator_roles = 'opsrv_view_operator_roles',
  opsrv_view_roles_and_permissions = 'opsrv_view_roles_and_permissions',
  opsrv_view_operators = 'opsrv_view_operators',
  send_contract = 'send_contract',
  send_growth_contract = 'send_growth_contract',
  spacestation_companies_write_access = 'spacestation_companies_write_access',
  spacestation_inventory_write_access = 'spacestation_inventory_write_access',
  spacestation_members_write_access = 'spacestation_members_write_access',
  spacestation_security_only_access = 'spacestation_security_only_access',
  update_commitment_term = 'update_commitment_term',
  update_employee_email_address = 'update_employee_email_address',
  update_employee_roles = 'update_employee_roles',
  update_notice_period = 'update_notice_period',
  update_occupiable_pricing_capacity = 'update_occupiable_pricing_capacity',
  update_open_dates = 'update_open_dates',
  upload_manual_contracts = 'upload_manual_contracts',
  upload_terms_of_service = 'upload_terms_of_service',
  view_account_info = 'view_account_info',
  view_company_mimo = 'view_company_mimo',
  view_error_records = 'view_error_records',
  view_member_info = 'view_member_info',
  view_occupancy_info = 'view_occupancy_info',
  view_occupiable_info = 'view_occupiable_info',
  view_waitlist = 'view_waitlist',
  virtual_reservable_matching = 'virtual_reservable_matching',
  voyager_breakeven_view = 'voyager_breakeven_view',
  voyager_breakeven_edit = 'voyager_breakeven_edit',
  voyager_breakeven_publish = 'voyager_breakeven_publish',
  voyager_price_view = 'voyager_price_view',
  voyager_price_edit = 'voyager_price_edit',
  voyager_price_publish = 'voyager_price_publish',
  voyager_discount_view = 'voyager_discount_view',
  voyager_discount_edit = 'voyager_discount_edit',
  voyager_discount_publish = 'voyager_discount_publish',
  voyager_discount_curve_manage = 'voyager_discount_curve_manage',
  voyager_discount_curve_apply = 'voyager_discount_curve_apply',
  voyager_system_admin = 'voyager_system_admin',
}

// A collection of known roles. No longer used for Typescript checks.
export enum EmployeeRoles {
  alchemist_admin = 'alchemist_admin',
  alchemist_building_manager = 'alchemist_building_manager',
  alchemist_city_lead = 'city_lead',
  alchemist_pricing = 'alchemist_pricing',
  alchemist_tax_manager = 'alchemist_tax_manager',
  alchemist_user = 'alchemist_user',
  application_admin = 'application_admin',
  billing = 'billing',
  city_lead = 'city_lead',
  commons_support = 'commons_support',
  community_management = 'community_management',
  developer = 'developer',
  digital_community_liaison = 'digital_community_liaison',
  digital_support = 'digital_support',
  enterprise_sales = 'enterprise_sales',
  manual_agreement_upload = 'manual_agreement_upload',
  ops_lead = 'ops_lead',
  security = 'security',
  voyager_breakeven_user = 'voyager_breakeven_user',
  voyager_breakeven_administrator = 'voyager_breakeven_administrator',
  voyager_price_user = 'voyager_price_user',
  voyager_price_administrator = 'voyager_price_administrator',
  voyager_discount_user = 'voyager_discount_user',
  voyager_discount_administrator = 'voyager_discount_administrator',
  voyager_system_administrator = 'voyager_system_administrator',
}

export type OperatorServiceRolesMap = {
  [key: string]: boolean;
};

export type OperatorServicePermissionsMap = {
  [key: string]: boolean;
};
