import React from 'react';
import cn from 'classnames';
import { Table, TableCellProps } from 'semantic-ui-react';

import Divider from '../Divider/Divider';

export interface TableDividerProps extends TableCellProps {
  content?: React.ReactNode;
  colspan: number;
}

const TableDivider = ({ content, colspan, className, ...rest }: TableDividerProps) => {
  return (
    <Table.Cell colspan={colspan} className={cn(className, 'divider')} {...rest}>
      <div className="container">
        <div className="content">{content}</div>
        <Divider dashed />
      </div>
    </Table.Cell>
  );
};

export default TableDivider;
