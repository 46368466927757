import React from 'react';
import cn from 'classnames';

import Button, { ButtonProps } from '../Button';

const removeActiveClassName = (className?: string): string | undefined => {
  if (!className) {
    return className;
  }

  const classNamesArray = className.split(' ');
  const activeClassNameIndex = classNamesArray.indexOf('active');

  if (activeClassNameIndex === -1) {
    return className;
  }

  classNamesArray.splice(activeClassNameIndex, 1);

  return cn(classNamesArray);
};

export const Chip: React.FC<ButtonProps> = ({ className, ...props }) => {
  // We removing `active` className to fix bug in chip dropdown where active className is added when dropdown is open
  // https://jira.weworkers.io/browse/DTR-106
  const chipClassName = removeActiveClassName(className);

  return <Button circular basic className={chipClassName} {...props} />;
};

Chip.displayName = 'Chip';

export default Chip;
