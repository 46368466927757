import React from 'react';
import {
  Label,
  LabelProps,
  StrictLabelProps,
  LabelDetail,
  LabelGroup,
  LabelGroupProps,
  StrictLabelGroupProps,
} from 'semantic-ui-react';

export type TagGroupProps = LabelGroupProps;

export type StrictTagGroupProps = StrictLabelGroupProps;

export const TagGroup: React.FC<TagGroupProps> = (props) => {
  return <LabelGroup {...props} tag />;
};

export type TagProps = LabelProps;

export type StrictTagProps = StrictLabelProps;

class Tag extends React.Component<TagProps> {
  static Detail = LabelDetail;

  static Group = TagGroup;

  render() {
    return <Label {...this.props} tag />;
  }
}

export default Tag;
