import React from 'react';
import { Form as SemanticForm, FormProps } from 'semantic-ui-react';

import FormField from './FormField';

export { FormProps, StrictFormProps } from 'semantic-ui-react';

class Form extends React.Component<FormProps> {
  static Field: typeof FormField = FormField;

  static Group: typeof SemanticForm.Group = SemanticForm.Group;
  // static Button = SemanticForm.Button;
  // static Checkbox = SemanticForm.Checkbox;
  // static Dropdown = SemanticForm.Dropdown;
  // static Input = SemanticForm.Input;
  // static Radio = SemanticForm.Radio;
  // static Select = SemanticForm.Select;
  // static TextArea = SemanticForm.TextArea;

  render(): React.ReactNode {
    return <SemanticForm {...this.props} />;
  }
}

export default Form;
