import { Button as SemanticButton } from 'semantic-ui-react';

export const Button: typeof SemanticButton = SemanticButton;

export {
  ButtonContent,
  ButtonGroup,
  ButtonOr,
  StrictButtonProps,
  ButtonProps,
  ButtonContentProps,
  StrictButtonContentProps,
  ButtonGroupProps,
  StrictButtonGroupProps,
  ButtonOrProps,
  StrictButtonOrProps,
} from 'semantic-ui-react';

export default Button;
