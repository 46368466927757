import { setContextClient } from 'api/authClient';
import { IContextClient } from 'api/types';

interface ConfigureOptions {
  environment: string;
  contextClient?: IContextClient;
}

let environment: string | undefined;

export const configure = (options: ConfigureOptions): void => {
  if (!options.contextClient || !options.environment) {
    throw new Error('configure called without an auth client.');
  }

  environment = options.environment;
  setContextClient(options.contextClient);
};

export const getEnvironment = (): string => {
  if (!environment) {
    throw new Error('Environment not set, please call configure with an environment before using this library.');
  }

  return environment;
};
