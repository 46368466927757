import React from 'react';
import {
  Table as SemanticTable,
  TableCell,
  TableBody,
  TableFooter,
  TableHeader,
  TableRow,
  TableProps as SemanticTableProps,
  StrictTableProps as SemanticStrictTableProps,
} from 'semantic-ui-react';

import TableDivider from './TableDivider';
import TableHeaderCell from './TableHeaderCell';

export interface StrictTableProps extends SemanticStrictTableProps {}

export interface TableProps extends SemanticTableProps, StrictTableProps {}

export default class Table extends React.Component<TableProps> {
  static Divider: typeof TableDivider = TableDivider;

  static Cell: typeof TableCell = TableCell;

  static Body: typeof TableBody = TableBody;

  static Footer: typeof TableFooter = TableFooter;

  static Header: typeof TableHeader = TableHeader;

  static HeaderCell: typeof TableHeaderCell = TableHeaderCell;

  static Row: typeof TableRow = TableRow;

  render(): React.ReactNode {
    return <SemanticTable {...this.props} />;
  }
}
