import { SemanticSIZES } from 'semantic-ui-react/dist/commonjs/generic';
import React from 'react';
import cn from 'classnames';
// @ts-ignore
import { getElementType } from 'semantic-ui-react/dist/commonjs/lib';

export interface SkeletonImageProps extends StrictSkeletonImageProps {
  [key: string]: any;
}
export interface StrictSkeletonImageProps {
  /** An element type to render as (string or function). */
  as?: any;

  /** Skeleton image size. */
  size?: SemanticSIZES;

  /** Additional classes. */
  className?: string;

  /** Content. */
  children?: React.ReactNode;

  /** Is animated. */
  animated?: boolean;

  /** Is Circular.  */
  circular?: boolean;

  /** Is rectangular */
  rectangular?: boolean;
}

export const SkeletonImage: React.FC<SkeletonImageProps> = (props) => {
  const { size, animated = true, circular, rectangular, className, ...rest }: SkeletonImageProps = props;
  const classes = cn(className, 'skeleton-image', 'ui', 'skeleton', {
    [size as string]: !!size,
    animation: animated,
    circular,
    rectangular,
  });

  const ElementType = getElementType(SkeletonImage, props);

  return <ElementType className={classes} {...rest} />;
};

SkeletonImage.displayName = 'SkeletonImage';

export default SkeletonImage;
