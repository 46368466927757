export default {
    version: 8,
    name: '3D Custom Color Floor Plan',
    metadata: {
        'mapbox:autocomposite': true,
        'mapbox:type': 'template',
        'mapbox:sdk-support': {
            android: '9.3.0',
            ios: '5.10.0',
            js: '2.3.0'
        },
        'mapbox:trackposition': false,
        'mapbox:groups': {},
        'mapbox:uiParadigm': 'layers'
    },
    center: [0.00010923435752374644, 0.00007655248971616402],
    zoom: 20.31705531991717,
    bearing: 20.799999999999926,
    pitch: 65.49999999999991,
    light: {
        intensity: 0.1,
        color: 'hsl(0, 100%, 100%)',
        anchor: 'viewport'
    },
    sources: {
        composite: {
            url: 'mapbox://telkowework.ck18a32sk0fer2oo2u9us5ilr-2qc1o,telkowework.ck18abaqr1uu82npk29n6gntf-7pn21,telkowework.cks55nzgb0fb629qesn506x2o-7c5c3',
            type: 'vector'
        }
    },
    sprite: 'mapbox://sprites/telkowework/cksewtulf9o6h17o7794374zy/draft',
    glyphs: 'mapbox://fonts/telkowework/{fontstack}/{range}.pbf',
    layers: [
        {
            id: 'WSFloor3D',
            type: 'fill-extrusion',
            source: 'composite',
            'source-layer': 'SF36Floors',
            layout: {},
            paint: {
                'fill-extrusion-height': 0.19,
                'fill-extrusion-color': '#ffffff'
            }
        },
        {
            id: 'WSRoomWork',
            type: 'fill-extrusion',
            source: 'composite',
            'source-layer': 'SF36Rooms_5',
            filter: ['match', ['get', 'programTypeLabel'], ['WORK'], true, false],
            paint: {
                'fill-extrusion-color': 'hsl(180, 60%, 81%)',
                'fill-extrusion-base': 0.2,
                'fill-extrusion-opacity': 0.9,
                'fill-extrusion-height': 0.4
            }
        },
        {
            id: 'WSRoomMeet',
            type: 'fill-extrusion',
            source: 'composite',
            'source-layer': 'SF36Rooms_5',
            filter: ['match', ['get', 'programTypeLabel'], ['MEET'], true, false],
            paint: {
                'fill-extrusion-color': 'hsl(100, 79%, 82%)',
                'fill-extrusion-opacity': 0.9,
                'fill-extrusion-base': 0.2,
                'fill-extrusion-height': 0.4
            }
        },
        {
            id: 'WSRoomWe',
            type: 'fill-extrusion',
            source: 'composite',
            'source-layer': 'SF36Rooms_5',
            filter: ['match', ['get', 'programTypeLabel'], ['WE'], true, false],
            paint: {
                'fill-extrusion-color': 'hsl(45, 100%, 73%)',
                'fill-extrusion-opacity': 0.9,
                'fill-extrusion-base': 0.2,
                'fill-extrusion-height': 0.4
            }
        },
        {
            id: 'WSRoomOther',
            type: 'fill-extrusion',
            source: 'composite',
            'source-layer': 'SF36Rooms_5',
            filter: ['match', ['get', 'programTypeLabel'], ['WORK', 'MEET', 'WE', 'CIRCULATE'], false, true],
            paint: {
                'fill-extrusion-height': 0.4,
                'fill-extrusion-color': 'hsl(0, 0%, 92%)',
                'fill-extrusion-base': 0.2
            }
        },
        {
            id: 'WSWall',
            type: 'fill-extrusion',
            source: 'composite',
            'source-layer': 'SF36Walls2',
            layout: { visibility: 'none' },
            paint: {
                'fill-extrusion-color': 'hsl(0, 0%, 70%)',
                'fill-extrusion-height': 0.7,
                'fill-extrusion-base': 0.2
            }
        },
        {
            id: 'WSRoomWorkLabels',
            type: 'symbol',
            source: 'composite',
            'source-layer': 'SF36Rooms_5',
            filter: ['match', ['get', 'programTypeLabel'], ['WORK'], true, false],
            layout: {
                'text-field': ['get', 'roomNumber'],
                'text-font': ['Apercu WeWork Mono Bold'],
                'text-size': ['interpolate', ['exponential', 1.96], ['zoom'], 0, 0, 21, 18],
                'icon-image': 'Door',
                visibility: 'none'
            },
            paint: { 'text-opacity': 0 }
        }
    ],
    created: '2021-08-16T17:27:34.203Z',
    modified: '2021-09-01T20:39:44.375Z',
    id: 'cksewtulf9o6h17o7794374zy',
    owner: 'telkowework',
    visibility: 'private',
    draft: true
}
