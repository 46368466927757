export enum FeatureType {
    BUILDING = 'building',
    FLOOR = 'floor',
    ROOM = 'room',
    OBJECT = 'object',
    WALL = 'wall',
    DOOR = 'door',
    PORTAL = 'portal',
    ANNOTATION = 'annotation'
}

export type ISpaceData<T> = {
    [x in FeatureType]: T
}

export class SpaceData<T> implements ISpaceData<T> {
    annotation: T
    building: T
    door: T
    floor: T
    object: T
    portal: T
    room: T
    wall: T

    constructor(annotation: T, building: T, door: T, floor: T, object: T, portal: T, room: T, wall: T) {
        this.annotation = annotation
        this.building = building
        this.door = door
        this.floor = floor
        this.object = object
        this.portal = portal
        this.room = room
        this.wall = wall
    }

    static all<T>(value: T): SpaceData<T> {
        return new SpaceData(value, value, value, value, value, value, value, value)
    }

    forEach(call: (type: FeatureType, data: T) => void) {
        Object.entries(this).forEach((entry) => {
            call(entry[0] as FeatureType, entry[1])
        })
    }
}
