import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DayPickerInputProps as DatePickerInputProps, InputClassNames } from 'react-day-picker';

import { defaultProps as defaultDatePickerProps } from '../DatePicker/DatePicker';

export { DayPickerInputProps as DatePickerInputProps } from 'react-day-picker';

const classNames: InputClassNames = {
  container: 'ui input datepicker-input',
  overlayWrapper: 'overlay-wrapper',
  overlay: 'overlay',
};

const defaultInputProps = {
  className: 'ui input',
};

export const DatePickerInput: React.FC<DatePickerInputProps> = (props) => {
  const { dayPickerProps, inputProps, ...rest } = props;

  const mergedDayPickerProps = {
    ...defaultDatePickerProps,
    ...dayPickerProps,
  };

  const mergedInputProps = {
    ...defaultInputProps,
    ...inputProps,
  };

  return (
    <DayPickerInput
      dayPickerProps={mergedDayPickerProps}
      inputProps={mergedInputProps}
      classNames={classNames}
      {...rest}
    />
  );
};

DatePickerInput.displayName = 'DatePickerInput';

export default DatePickerInput;
