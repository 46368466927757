import React from 'react';
import { Checkbox as SemanticCheckbox, CheckboxProps } from 'semantic-ui-react';
import cn from 'classnames';

import Icon from '../Icon';

export { CheckboxProps, StrictCheckboxProps } from 'semantic-ui-react';

export const Checkbox: React.FC<CheckboxProps> = ({ label, fitted, className, id, ...rest }) => {
  const htmlFor = typeof id === 'undefined' ? id : String(id);

  const labelElement = (
    <label htmlFor={htmlFor}>
      <Icon name="check" />
      {label}
    </label>
  );

  return (
    <SemanticCheckbox
      className={cn(className, {
        fitted: fitted ?? !label,
      })}
      label={labelElement}
      id={htmlFor}
      {...rest}
    />
  );
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;
