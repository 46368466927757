import React from 'react';
import {
  Pagination as SemanticPagination,
  PaginationProps,
  SemanticShorthandItem,
  PaginationItemProps,
} from 'semantic-ui-react';
import hoistNonReactStatics from 'hoist-non-react-statics';

const ArrowIconItems: Record<string, SemanticShorthandItem<PaginationItemProps>> = {
  FIRST: {
    'aria-label': 'First item',
    content: '⇤',
  },
  LAST: {
    'aria-label': 'Last item',
    content: '⇥',
  },
  NEXT: {
    'aria-label': 'Next item',
    content: '→',
  },
  PREVIOUS: {
    'aria-label': 'Previous item',
    content: '←',
  },
};

const ExtendedPagination: React.FC<PaginationProps> = ({
  firstItem = ArrowIconItems.FIRST,
  lastItem = ArrowIconItems.LAST,
  nextItem = ArrowIconItems.NEXT,
  prevItem = ArrowIconItems.PREVIOUS,
  ...props
}) => (
  <SemanticPagination {...props} firstItem={firstItem} lastItem={lastItem} nextItem={nextItem} prevItem={prevItem} />
);

export const Pagination = hoistNonReactStatics(ExtendedPagination, SemanticPagination);

Pagination.displayName = 'Pagination';

export { PaginationProps, StrictPaginationProps } from 'semantic-ui-react';

export default Pagination;
