import { FPObject } from './FloorPlanData.public.interfaces'
import { Point } from '@wework/proton-grpcwebtext/space/spatial_resource/shared_pb'

/// Modifies objects in place and returns the same list.
export function cleanupObjects(objects: FPObject[]): FPObject[] {
    const tablesById = new Map<string, FPObject>()
    const delayedChairs: FPObject[] = []

    objects.forEach((o) => {
        if (o.objectType === 'TABLE') tablesById.set(o.uuid, o)
        if (o.objectType === 'CHAIR') {
            if (o.parentObjectUuid) {
                const parent = tablesById.get(o.parentObjectUuid as string)
                if (parent) {
                    if (parent.objectType === 'TABLE') o.locationAngle = chairRotationAngleRadians(o, parent)
                } else {
                    delayedChairs.push(o)
                }
            }
        }
    })
    delayedChairs.forEach((o) => {
        if (o.parentObjectUuid) {
            const parent = tablesById.get(o.parentObjectUuid as string)
            if (parent?.objectType === 'TABLE') o.locationAngle = chairRotationAngleRadians(o, parent)
        }
    })

    return objects
}

function chairRotationAngleRadians(chair: FPObject, table: FPObject): number {
    // fix centroids:
    chair.centroidInFeet = averageBoundaryPoints(chair.projectedBoundariesInFeet?.outerBoundary?.pointsList)
    table.centroidInFeet = averageBoundaryPoints(table.projectedBoundariesInFeet?.outerBoundary?.pointsList)
    // calculate new rotation angle
    return (
        Math.PI / 2 -
        Math.atan2(table.centroidInFeet.y - chair.centroidInFeet.y, table.centroidInFeet.x - chair.centroidInFeet.x)
    )
}

/**
 * @description Calculates the center of a given polygon boundary. If the first and last point are the same, this first/last value is only included in teh average once.
 * @param points - An array of IPoint objects describing a polygon geometry.
 */
export function averageBoundaryPoints(points: Point.AsObject[] | undefined): Point.AsObject {
    if (!Array.isArray(points) || points.length === 0) return { x: 0, y: 0, z: 0 }
    const isFirstRepeated =
        points.length != 1 &&
        points[0]?.x === points[points.length - 1]?.x &&
        points[0]?.y === points[points.length - 1]?.y
    const total = { x: 0, y: 0, z: 0 }
    const count = isFirstRepeated ? points.length - 1 : points.length
    points.forEach((point) => {
        total.x += point.x
        total.y += point.y
        total.z += point.z
    })
    if (isFirstRepeated) {
        total.x -= points[0]?.x
        total.y -= points[0]?.y
        total.z -= points[0]?.z
    }
    total.x /= count
    total.y /= count
    return total
}
