import { Projection } from './FloorPlanToGeoJsonConverter.interfaces'
import { IPoint } from '../../services/FloorPlanData.public.interfaces'

export class WeSpaceProjection implements Projection {
    static defaultFeetPerDegree = 365220.0 // feet per degree longitude at the equator
    static defaultOrigin = [0, 0]

    public feetPerDegree: number
    public origin: number[]

    constructor(feetPerDegree = WeSpaceProjection.defaultFeetPerDegree, origin = WeSpaceProjection.defaultOrigin) {
        this.feetPerDegree = feetPerDegree
        this.origin = origin
    }

    public project(coordinate: number[]): IPoint {
        return {
            x: (coordinate[0] - this.origin[0]) * this.feetPerDegree,
            y: (coordinate[1] - this.origin[1]) * this.feetPerDegree
        }
    }

    public unProject(point: IPoint): [number, number] {
        return [point.x / this.feetPerDegree + this.origin[0], point.y / this.feetPerDegree + this.origin[1]]
    }
}

export function srsPointFromArray(coordinate: number[]): IPoint {
    return { x: coordinate[0], y: coordinate[1] }
}

export function arrayFromPoint(point: IPoint): number[] {
    return [point.x, point.y]
}
