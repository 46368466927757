const fetches: { [key: string]: Promise<Response> } = {};

const FETCH_PROMISE_CACHE_DURATION = 60 * 1000; // 60s

// this function caches repeated calls that are made to the same url
export const cachedFetch = (url: string, options: RequestInit): Promise<Response> => {
  if (!!fetches[url]) {
    return fetches[url];
  }

  const fetchPromise = fetch(url, options);
  fetches[url] = fetchPromise;

  setTimeout(() => {
    delete fetches[url];
  }, FETCH_PROMISE_CACHE_DURATION);

  return fetchPromise;
};

// exported for testing
export const resetCachedFetch = (): void => {
  Object.keys(fetches).forEach((key: string) => {
    delete fetches[key];
  });
};
