/* eslint-disable @typescript-eslint/unbound-method */
import mapboxgl, {
    EventData,
    GeoJSONSource,
    LngLatBoundsLike,
    Map as MapView,
    MapboxGeoJSONFeature,
    MapMouseEvent,
    Point,
    Style
} from 'maplibre-gl'
import { Feature, FeatureCollection, Geometry, LineString, Point as GeoJSONPoint, Polygon } from 'geojson'
import defaultMapboxStyle from '../assets/defaultMapboxStyle'
import { formatStyle } from './styleFormatter'
import {
    convertAnnotationsToGeoJSON,
    isLineAnnotation,
    isPointAnnotation,
    isPolygonAnnotation
} from './GeoJSONConversion/AnnotationGeoJSONConverter'
import {
    Annotation,
    DragType,
    EventFeatures,
    FloorPlanBaseMapStyle,
    FloorPlanCamera,
    FloorPlanConfig,
    FloorPlanDragEvent,
    FloorPlanEvent,
    FloorPlanLoadEvent,
    FloorPlanShouldEvent,
    PolygonAnnotationStyle
} from './FloorPlan.public.interfaces'
import { FloorFeatures, FloorPlanData, FloorPlanDataService } from '../services/FloorPlanDataService'
import { FeatureType, SpaceData } from './SpaceData'
import { arrayFromPoint, WeSpaceProjection } from './GeoJSONConversion/WeSpaceProjection'
import { FloorPlanFeatureProperties, Projection } from './GeoJSONConversion/FloorPlanToGeoJsonConverter.interfaces'
import { FloorPlanToGeoJsonConverter } from './GeoJSONConversion/FloorPlanToGeoJsonConverter'
import lodash from 'lodash'
import { PolygonSnapManager } from './snapping/PolygonSnapManager'
import { fastHash, rotateCoord, translateCoord } from './snapping/helpers'
import { FPDoor, FPFloor, FPRoom, FPWall, IPoint } from '../services/FloorPlanData.public.interfaces'
import revitMapboxStyle from '../assets/revitMapboxStyle'
import onDemand3DStyle from '../assets/OnDemand3D'
import inventoryManagerMapboxStyle from '../assets/inventoryManagerMapboxStyle'
import greyStyle from '../assets/grey'
import calculateBbox from '@turf/bbox'
import bbox from '@turf/bbox'

interface Drag {
    ids: Set<string>
    annotations: Annotation[]
    startFeatures: Feature<Geometry, FloorPlanFeatureProperties>[]
    currentFeatures: Feature<Geometry, FloorPlanFeatureProperties>[]
    startLngLat: number[]
}

interface Rotation extends Drag {
    startPoint: Point
    startTheta: number
    centerLngLat: number[]
}

type MapboxFeature = MapboxGeoJSONFeature & Feature<Geometry, FloorPlanFeatureProperties>

export class FloorPlan {
    private config: FloorPlanConfig
    private _mapStyle: FloorPlanBaseMapStyle
    private map: MapView
    private isStyleLoaded = false
    private data: FloorPlanData = new FloorPlanData()
    private dataGeoJSON: SpaceData<Feature<Geometry, FloorPlanFeatureProperties>[]> = SpaceData.all([])

    private projection = new WeSpaceProjection()
    private drag: Drag | null = null
    private rotation: Rotation | null = null

    private _floorId: string | null = null
    private _annotations: Annotation[] = []

    floorHoverEnabled = false
    roomHoverEnabled = false
    wallHoverEnabled = false
    doorHoverEnabled = false
    featuresToLoad = FloorFeatures.default
    previousFeaturesToLoad: FloorFeatures = []

    // event callbacks
    onWillLoad?: () => void
    onLoad?: (e: FloorPlanLoadEvent) => void
    onMouseMove?: (e: FloorPlanEvent) => void
    onAnnotationDrag?: (drag: FloorPlanDragEvent) => void
    onAnnotationRotate?: (drag: FloorPlanDragEvent) => void
    shouldHoverAnnotationIds?: (drag: FloorPlanShouldEvent) => string[] | void
    shouldDragAnnotationIds?: (drag: FloorPlanShouldEvent) => string[] | void
    shouldRotateAnnotationIds?: (e: FloorPlanShouldEvent) => string[] | void

    private _onRoomCustomStyle?: (room: FPRoom) => PolygonAnnotationStyle | null
    private _onWallCustomStyle?: (wall: FPWall) => PolygonAnnotationStyle | null
    private _onDoorCustomStyle?: (door: FPDoor) => PolygonAnnotationStyle | null
    private _onFloorCustomStyle?: (door: FPFloor) => PolygonAnnotationStyle | null
    private _onClick?: (e: MapMouseEvent) => void
    private _onContextMenu?: (e: MapMouseEvent) => void
    private _onCameraChanged?: (e: FloorPlanEvent) => void

    private _hoveredIds: Set<string> = new Set([])
    private _selectedIds: Set<string> = new Set([])
    private registeredImageURLs: Set<string> = new Set([])
    private snapManager = new PolygonSnapManager()

    private mapLoadCallback = () => {
        this.isStyleLoaded = true
        this.updateBasemapSources(this.dataGeoJSON)
        this.updateMapSource(FeatureType.ANNOTATION, this.dataGeoJSON.annotation)

        // change pitch for 3d maps
        switch (this._mapStyle) {
            case FloorPlanBaseMapStyle.DEFAULT:
            case FloorPlanBaseMapStyle.REVIT:
                this.map.setPitch(0)
                break
            case FloorPlanBaseMapStyle.ONDEMAND3D:
                this.map.setPitch(60)
                break
        }
    }

    constructor(containerElementId: string | HTMLElement, config: FloorPlanConfig) {
        this.config = config
        this._mapStyle = FloorPlanBaseMapStyle.DEFAULT

        this.map = new MapView({
            container: containerElementId,
            style: formatStyle(defaultMapboxStyle as Style, config.environment),
            center: [0, 0],
            zoom: 21,
            preserveDrawingBuffer: true
        })

        this.map.boxZoom.disable()

        this.map.on('load', this.mapLoadCallback)
        this.registerOnMoveCallback()
        this.registerDragAndRotateCallbacks()
    }

    get mapStyle(): FloorPlanBaseMapStyle {
        return this._mapStyle
    }

    checkMapLoad(timeoutMs: number) {
        if (this.map.isStyleLoaded()) this.mapLoadCallback()
        else setTimeout(this.checkMapLoad.bind(this), timeoutMs)
    }

    set mapStyle(style: FloorPlanBaseMapStyle) {
        if (style === this._mapStyle) return
        this._mapStyle = style

        let mapboxStyle: Style
        switch (style || FloorPlanBaseMapStyle.DEFAULT) {
            case FloorPlanBaseMapStyle.DEFAULT:
                mapboxStyle = defaultMapboxStyle as Style
                break
            case FloorPlanBaseMapStyle.REVIT:
                mapboxStyle = revitMapboxStyle as Style
                break
            case FloorPlanBaseMapStyle.ONDEMAND3D:
                mapboxStyle = onDemand3DStyle as Style
                break
            case FloorPlanBaseMapStyle.INVENTORY_MANAGER:
                mapboxStyle = inventoryManagerMapboxStyle as Style
                break
            case FloorPlanBaseMapStyle.GREY:
                mapboxStyle = greyStyle as Style
        }

        this.isStyleLoaded = false
        this.map.setStyle(formatStyle(mapboxStyle, this.config.environment))
        this.checkMapLoad(100)
    }

    get floorId(): string | null {
        return this._floorId
    }

    resetMap() {
        this.renderBaseMap()
        this.zoomTo(this.dataGeoJSON)
        if (this.onLoad) {
            this.onLoad({
                error: null,
                floorPlan: this.data
            })
        }
    }

    set floorId(floorId: string | null) {
        if (this.onWillLoad) {
            this.onWillLoad()
        }
        if (
            floorId &&
            (!lodash.isEqual(floorId, this._floorId) ||
                !lodash.isEqual(this.previousFeaturesToLoad, this.featuresToLoad))
        ) {
            this._floorId = floorId
            this.previousFeaturesToLoad = this.featuresToLoad
            // as INVENTORY_MANAGER style only uses annotation.
            // fetching data is not required
            if (this._mapStyle === FloorPlanBaseMapStyle.INVENTORY_MANAGER) {
                setTimeout(() => this.resetMap(), 100)
            } else {
                this.loadFloor(floorId).then(
                    (data) => {
                        this.data = data
                        this.resetMap()
                    },
                    (error) => {
                        if (this.onLoad) {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            this.onLoad({ error: error, floorPlan: null })
                        } else {
                            console.info('floor plan fetch error:', error)
                        }
                    }
                )
            }
        } else {
            this.data = new FloorPlanData()
            const annotationFeatures = this.dataGeoJSON.annotation
            this.dataGeoJSON = SpaceData.all([])
            this.dataGeoJSON.annotation = annotationFeatures
            this.updateBasemapSources(this.dataGeoJSON)
            if (this.onLoad) {
                this.onLoad({ error: null, floorPlan: this.data })
            }
        }
    }

    get floorPlan(): FloorPlanData {
        return this.data
    }

    get annotations(): Annotation[] {
        return this._annotations
    }

    set annotations(annotations: Annotation[]) {
        this._annotations = annotations
        this.loadAndRegisterAnnotationImages(annotations)
        this.dataGeoJSON.annotation = convertAnnotationsToGeoJSON(annotations, this.projection)
        this.updateSelection(false)
        this.updateMapSource(FeatureType.ANNOTATION, this.dataGeoJSON.annotation)
    }

    get camera(): FloorPlanCamera {
        return {
            center: this.projection.project(this.map.getCenter().toArray() || [0, 0]),
            zoom: this.map.getZoom(),
            offsetToMapPoint: (x, y) => this.projection.project(this.map.unproject([x, y]).toArray()),
            mapPointToOffset: (point) => this.map.project(this.projection.unProject(point))
        }
    }

    set camera(camera: FloorPlanCamera) {
        this.map.flyTo({
            center: this.projection.unProject(camera.center),
            zoom: camera.zoom
        })
    }

    // Event callback setters

    set selectedIds(ids: string[]) {
        this._selectedIds = new Set(ids)
        this.updateSelection(true)
    }

    set onClick(onClick: ((e: FloorPlanEvent) => void) | undefined) {
        if (this._onClick) {
            this.map.off('click', this._onClick)
        }
        if (onClick) {
            const callback = (e: MapMouseEvent) => {
                const features = this.getMapFeaturesAtPoint(e.point)
                const event = this.createFloorPlanEvent(features, e.lngLat.toArray(), e.originalEvent)
                onClick(event)
            }
            this._onClick = callback
            this.map.on('click', callback)
        } else {
            this._onClick = undefined
        }
    }

    set onContextMenu(onContextMenu: ((e: FloorPlanEvent) => void) | undefined) {
        if (this._onContextMenu) {
            this.map.off('contextmenu', this._onContextMenu)
        }
        if (onContextMenu) {
            const callback = (e: MapMouseEvent) => {
                const features = this.getMapFeaturesAtPoint(e.point)
                const event = this.createFloorPlanEvent(features, e.lngLat.toArray(), e.originalEvent)
                onContextMenu(event)
            }
            this._onContextMenu = callback
            this.map.on('contextmenu', callback)
        } else {
            this._onContextMenu = undefined
        }
    }

    set onCameraChanged(onCameraChanged: ((e: FloorPlanCamera) => void) | undefined) {
        if (this._onCameraChanged) {
            this.map.off('move', this._onCameraChanged)
        }
        if (onCameraChanged) {
            const callback = () => {
                onCameraChanged(this.camera)
            }
            this._onCameraChanged = callback
            this.map.on('move', callback)
        } else {
            this._onCameraChanged = undefined
        }
    }

    get allowsUserInteraction() {
        return this.map.dragPan.isEnabled()
    }

    set allowsUserInteraction(enabled: boolean) {
        const handlers = [
            'scrollZoom',
            // 'boxZoom', // permanently disabled above
            'dragRotate',
            'dragPan',
            'keyboard',
            'doubleClickZoom',
            'touchZoomRotate',
            'touchPitch'
        ]
        if (enabled) {
            // eslint-disable-next-line
            handlers.forEach((handler) => this.map[handler].enable())
        } else {
            // eslint-disable-next-line
            handlers.forEach((handler) => this.map[handler].disable())
        }
    }

    set download(_settings: { name: string }) {
        if (!_settings?.name) return

        const urlForDownload = this.map.getCanvas().toDataURL('image/png')
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = urlForDownload
        // the filename you want
        a.download = _settings.name + '.png'
        document.body.appendChild(a)
        a.click()
    }

    set onRoomCustomStyle(style: ((room: FPRoom) => PolygonAnnotationStyle | null) | undefined) {
        this._onRoomCustomStyle = style
        this.renderBaseMap()
    }

    set onWallCustomStyle(style: ((wall: FPWall) => PolygonAnnotationStyle | null) | undefined) {
        this._onWallCustomStyle = style
        this.renderBaseMap()
    }

    set onDoorCustomStyle(style: ((door: FPDoor) => PolygonAnnotationStyle | null) | undefined) {
        this._onDoorCustomStyle = style
        this.renderBaseMap()
    }

    set onFloorCustomStyle(style: ((floor: FPFloor) => PolygonAnnotationStyle | null) | undefined) {
        this._onFloorCustomStyle = style
        this.renderBaseMap()
    }

    set degreesRotateTo(bearing: number) {
        this.easeTo(bearing)
    }

    set degreesTiltTo(pitch: number) {
        this.map.easeTo({
            pitch
        })
    }

    easeTo(bearing = 0, duration = 250, easing = (_x: number) => _x) {
        this.map.easeTo({
            bearing,
            duration,
            easing
        })
    }

    zoomToPoint(point: IPoint, animated: boolean = true, maxZoomLevel: number = Number.POSITIVE_INFINITY) {
        const feature: Feature<GeoJSONPoint> = {
            type: 'Feature',
            geometry: { type: 'Point', coordinates: this.projection.unProject(point) },
            properties: {}
        }
        if (feature) this.zoomToFeatureGeoJSONFeature(feature, animated, maxZoomLevel)
    }

    zoomToFeatureId(id: string, animated: boolean = true, maxZoomLevel: number = Number.POSITIVE_INFINITY) {
        let feature: Feature | null = null
        for (const data of Object.values(this.dataGeoJSON)) {
            for (const aFeature of data as Feature[]) {
                if (aFeature.id == id) {
                    feature = aFeature
                    break
                }
            }
            if (feature) break
        }

        if (feature) this.zoomToFeatureGeoJSONFeature(feature, animated, maxZoomLevel)
        if (!feature && id == this.floorId) {
            this.zoomTo(this.dataGeoJSON) // For floors without a floor polygon, zoom to contain all features.
        }
    }

    private zoomToFeatureGeoJSONFeature(feature: Feature, animated: boolean, maxZoomLevel: number) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const bounds = calculateBbox(feature) as [number, number, number, number]
        this.map.fitBounds(bounds, { animate: animated, maxZoom: maxZoomLevel })
    }

    // Render

    private renderBaseMap() {
        const existingAnnotations = this.dataGeoJSON.annotation
        const customStyles = {
            floor: this._onFloorCustomStyle,
            room: this._onRoomCustomStyle,
            wall: this._onWallCustomStyle,
            door: this._onDoorCustomStyle
        }
        this.dataGeoJSON = convertToGeoJSON(this.data, this.projection, customStyles)
        this.dataGeoJSON.annotation = existingAnnotations
        this.updateSelection(true)
    }

    // Register SDK callbacks with map

    private registerOnMoveCallback() {
        this.map.on('mousemove', (e) => {
            let features
            if (
                this.onMouseMove ||
                this.roomHoverEnabled ||
                this.wallHoverEnabled ||
                this.doorHoverEnabled ||
                this.floorHoverEnabled
            ) {
                features = this.getMapFeaturesAtPoint(e.point)
                if (this.onMouseMove) {
                    const event = this.createFloorPlanEvent(features, e.lngLat.toArray(), e.originalEvent)
                    this.onMouseMove(event)
                }
            } else {
                features = this.getMapAnnotationsAtPoint(e.point)
            }

            // set feature as hovered, giving annotations under room labels precedence over other features.
            const hoveredAnnotationFeature = features.find((f) => f.source === 'annotation')
            const annotation = hoveredAnnotationFeature
                ? this.annotations.find((a) => a.id === hoveredAnnotationFeature.id)
                : null

            const event: FloorPlanShouldEvent = {
                annotations: annotation ? [annotation] : [],
                point: this.projection.project(e.lngLat.toArray()),
                originalEvent: e.originalEvent
            }

            // ask `shouldHover` callback for a list of annotation ids
            const hoverIds = this.shouldHoverAnnotationIds
                ? this.shouldHoverAnnotationIds(event) || (annotation ? [annotation.id] : [])
                : annotation
                ? [annotation.id]
                : []

            this.updateHover(hoverIds)
        })
    }

    private registerDragAndRotateCallbacks() {
        this.map.on('mousedown', (e) => {
            if (
                // appropriate callbacks must be set to enable drag and rotation
                (!this.shouldDragAnnotationIds && !this.shouldRotateAnnotationIds) ||
                // if shift key is pressed, don't drag or rotate
                e.originalEvent.shiftKey
            ) {
                return
            }

            const annotationFeatures = this.getMapAnnotationsAtPoint(e.point) as Feature<
                Geometry,
                FloorPlanFeatureProperties
            >[]
            if (!annotationFeatures.length) return

            const ids = new Set(annotationFeatures.flatMap((f) => f.id))
            const annotations = this.annotations.filter((a) => ids.has(a.id))

            // control-click or right-click = rotate
            if (
                (e.originalEvent.ctrlKey ||
                    e.originalEvent.button == 2 ||
                    e.originalEvent.altKey ||
                    e.originalEvent.metaKey) &&
                this.shouldRotateAnnotationIds
            )
                this.handleRotationStart(e, annotations)
            else if (this.shouldDragAnnotationIds) this.handleDragStart(e, annotations)
        })
    }

    //Annotation Drag

    private handleDragStart(e: MapMouseEvent & EventData, annotations: Annotation[]) {
        // create should drag event
        const event: FloorPlanShouldEvent = {
            annotations: annotations,
            point: this.projection.project(e.lngLat.toArray()),
            originalEvent: e.originalEvent
        }

        // ask `shouldDrag` callback for a list of annotation ids
        const dragIds = new Set(this.shouldDragAnnotationIds ? this.shouldDragAnnotationIds(event) || [] : [])
        let annotationFeatures: Feature<Geometry, FloorPlanFeatureProperties>[] = []
        annotations = []
        if (dragIds.size) {
            // create lists of annotations and geoJSON features to drag
            annotationFeatures = this.dataGeoJSON.annotation.filter((f) => dragIds.has(f.id as string))
            annotations = this.annotations.filter((f) => dragIds.has(f.id))
        }

        if (annotations.length) {
            if (this.onAnnotationDrag) {
                this.onAnnotationDrag({
                    annotations: annotations,
                    type: DragType.START,
                    originalEvent: e.originalEvent
                })
            }
            // Prevent the default map drag behavior.
            e.preventDefault()
            this.drag = {
                ids: new Set(annotations.flatMap((a) => a.id)),
                annotations: annotations,
                startFeatures: lodash.cloneDeep(annotationFeatures),
                currentFeatures: annotationFeatures,
                startLngLat: [e.lngLat.lng, e.lngLat.lat]
            }
            this.snapManager.generateSnapIndex(this.dataGeoJSON.annotation)

            const dragMoveCallback = (e: MapMouseEvent & EventData) => {
                this._onDragMoved(e)
            }
            this.map.on('mousemove', dragMoveCallback)
            this.map.once('mouseup', (e) => {
                if (this.drag) {
                    if (this.onAnnotationDrag) {
                        this.onAnnotationDrag({
                            annotations: this.drag.annotations,
                            type: DragType.END,
                            originalEvent: e.originalEvent
                        })
                    }
                    this.drag = null
                    this.updateSelection(true)
                }
                this.map.off('mousemove', dragMoveCallback)
            })
        }
    }

    private _onDragMoved(e: mapboxgl.MapMouseEvent) {
        if (this.drag) {
            let transform = {
                translation: [e.lngLat.lng - this.drag.startLngLat[0], e.lngLat.lat - this.drag.startLngLat[1]],
                rotation: 0,
                rotationCenter: [0, 0]
            }

            if (e.originalEvent.shiftKey) {
                if (Math.abs(transform.translation[0]) > Math.abs(transform.translation[1])) {
                    transform.translation[1] = 0 // horizontal translation only
                } else {
                    transform.translation[0] = 0 // vertical translation only
                }
            } else {
                const startPolygons = this.drag.startFeatures.filter((a) => a.geometry.type == 'Polygon') as Feature<
                    Polygon,
                    FloorPlanFeatureProperties
                >[]
                if (startPolygons.length && startPolygons.length < 40) {
                    // Snapping
                    transform = this.snapManager.snapPolygons(startPolygons, transform.translation) || transform
                }
            }

            const zipped = this.drag.annotations.map((a, i) => {
                return {
                    annotation: a,
                    start: this.drag!.startFeatures[i],
                    current: this.drag!.currentFeatures[i]
                }
            })
            zipped.forEach((item) => {
                if (isPointAnnotation(item.annotation)) {
                    const start = item.start as Feature<GeoJSONPoint, FloorPlanFeatureProperties>
                    const updated = item.current as Feature<GeoJSONPoint, FloorPlanFeatureProperties>
                    updated.geometry.coordinates = translateCoord(
                        rotateCoord(start.geometry.coordinates, transform.rotationCenter, transform.rotation),
                        transform.translation
                    )
                    item.annotation.geometry.coordinates = arrayFromPoint(
                        this.projection.project(updated.geometry.coordinates)
                    )
                } else if (isLineAnnotation(item.annotation)) {
                    const start = item.start as Feature<LineString, FloorPlanFeatureProperties>
                    const updated = item.current as Feature<LineString, FloorPlanFeatureProperties>
                    updated.geometry.coordinates =
                        start.geometry?.coordinates.map((coord) =>
                            translateCoord(
                                rotateCoord(coord, transform.rotationCenter, transform.rotation),
                                transform.translation
                            )
                        ) || []
                    item.annotation.geometry.coordinates =
                        updated.geometry?.coordinates.map((coord) => arrayFromPoint(this.projection.project(coord))) ||
                        []
                } else if (isPolygonAnnotation(item.annotation)) {
                    const start = item.start as Feature<Polygon, FloorPlanFeatureProperties>

                    // TODO: when start.geometry.type === 'Point' it throws error
                    const updated = item.current as Feature<Polygon, FloorPlanFeatureProperties>
                    updated.geometry.coordinates = start.geometry.coordinates?.map((coordinates) =>
                        coordinates.map((coord) =>
                            translateCoord(
                                rotateCoord(coord, transform.rotationCenter, transform.rotation),
                                transform.translation
                            )
                        )
                    )
                    item.annotation.geometry.coordinates = updated.geometry.coordinates?.map((coords) =>
                        coords.map((c) => arrayFromPoint(this.projection.project(c)))
                    )
                } else {
                    console.log('Unsupported annotation type', item.annotation)
                }
            })

            if (this.onAnnotationDrag) {
                this.onAnnotationDrag({
                    annotations: this.drag.annotations,
                    type: DragType.MOVE,
                    originalEvent: e.originalEvent
                })
            }

            this.updateMapSource(FeatureType.ANNOTATION, this.dataGeoJSON.annotation)
        }
    }

    // Annotation Rotation

    private handleRotationStart(e: MapMouseEvent & EventData, annotations: Annotation[]) {
        // create should drag event
        const event: FloorPlanShouldEvent = {
            annotations: annotations,
            point: this.projection.project(e.lngLat.toArray()),
            originalEvent: e.originalEvent
        }

        // ask `shouldDrag` callback for a list of annotation ids
        const rotateIds = new Set(this.shouldRotateAnnotationIds ? this.shouldRotateAnnotationIds(event) || [] : [])

        let annotationFeatures: Feature<Geometry, FloorPlanFeatureProperties>[] = []
        annotations = []
        if (rotateIds.size) {
            // create lists of annotations and geoJSON features to drag
            annotationFeatures = this.dataGeoJSON.annotation.filter((f) => rotateIds.has(f.id as string))
            annotations = this.annotations.filter((f) => rotateIds.has(f.id))
        }

        if (annotations.length) {
            if (this.onAnnotationRotate) {
                this.onAnnotationRotate({
                    annotations: annotations,
                    type: DragType.START,
                    originalEvent: e.originalEvent
                })
            }
            // Prevent the default map drag behavior.
            e.preventDefault()

            // Calculate rotation center
            const featureCollection: FeatureCollection = { type: 'FeatureCollection', features: annotationFeatures }
            const box = bbox(featureCollection)
            const centerLngLat = [(box[0] + box[2]) / 2, (box[1] + box[3]) / 2]
            const centerPoint = this.map.project(centerLngLat as [number, number])
            const theta = Math.atan2(e.point.y - centerPoint.y, e.point.x - centerPoint.x)
            this.rotation = {
                ids: new Set(annotations.flatMap((a) => a.id)),
                annotations: annotations,
                startFeatures: lodash.cloneDeep(annotationFeatures),
                currentFeatures: annotationFeatures,
                startLngLat: [e.lngLat.lng, e.lngLat.lat],
                startPoint: centerPoint,
                startTheta: theta,
                centerLngLat: centerLngLat
            }

            const rotationChangedCallback = (e: MapMouseEvent & EventData) => {
                this._onRotationChanged(e)
            }
            this.map.on('mousemove', rotationChangedCallback)
            this.map.once('mouseup', () => {
                if (this.rotation) {
                    if (this.onAnnotationRotate) {
                        this.onAnnotationRotate({
                            annotations: this.rotation.annotations,
                            type: DragType.END,
                            originalEvent: e.originalEvent
                        })
                    }
                    this.rotation = null
                    this.updateSelection(true)
                }
                this.map.off('mousemove', rotationChangedCallback)
            })
        }
    }

    private _onRotationChanged(e: mapboxgl.MapMouseEvent) {
        if (this.rotation) {
            let theta =
                Math.atan2(e.point.y - this.rotation.startPoint.y, e.point.x - this.rotation.startPoint.x) -
                this.rotation.startTheta
            if (e.originalEvent.shiftKey) {
                const roundingInterval = Math.PI / 8
                theta = Math.round(theta / roundingInterval) * roundingInterval
            }

            const zipped = this.rotation.annotations.map((a, i) => {
                return {
                    annotation: a,
                    start: this.rotation!.startFeatures[i],
                    current: this.rotation!.currentFeatures[i]
                }
            })
            zipped.forEach((item) => {
                if (isPointAnnotation(item.annotation)) {
                    const start = item.start as Feature<GeoJSONPoint, FloorPlanFeatureProperties>
                    const updated = item.current as Feature<GeoJSONPoint, FloorPlanFeatureProperties>
                    updated.geometry.coordinates = rotateCoord(
                        start.geometry.coordinates,
                        this.rotation!.centerLngLat,
                        theta
                    )
                    item.annotation.geometry.coordinates = arrayFromPoint(
                        this.projection.project(item.annotation.geometry.coordinates)
                    )
                } else if (isLineAnnotation(item.annotation)) {
                    const start = item.start as Feature<LineString, FloorPlanFeatureProperties>
                    const updated = item.current as Feature<LineString, FloorPlanFeatureProperties>
                    updated.geometry.coordinates = start.geometry.coordinates.map((coord) =>
                        rotateCoord(coord, this.rotation!.centerLngLat, theta)
                    )
                    item.annotation.geometry.coordinates = updated.geometry.coordinates.map((coord) =>
                        arrayFromPoint(this.projection.project(coord))
                    )
                } else if (isPolygonAnnotation(item.annotation)) {
                    const start = item.start as Feature<Polygon, FloorPlanFeatureProperties>
                    const updated = item.current as Feature<Polygon, FloorPlanFeatureProperties>
                    updated.geometry.coordinates = start.geometry.coordinates.map((coordinates) =>
                        coordinates.map((coord) => rotateCoord(coord, this.rotation!.centerLngLat, theta))
                    )
                    item.annotation.geometry.coordinates = updated.geometry.coordinates.map((coords) =>
                        coords.map((c) => arrayFromPoint(this.projection.project(c)))
                    )
                }
            })

            if (this.onAnnotationRotate) {
                this.onAnnotationRotate({
                    annotations: this.rotation.annotations,
                    type: DragType.MOVE,
                    originalEvent: e.originalEvent
                })
            }

            this.updateMapSource(FeatureType.ANNOTATION, this.dataGeoJSON.annotation)
        }
    }

    // Networking

    private loadFloor(id: string): Promise<FloorPlanData> {
        const service = new FloorPlanDataService(
            this.config.environment,
            this.config.auth0TokenProvider,
            this.config.displayUnapprovedLatestHarvestData
        )
        const includeInventory = this.config.includeInventoryData === true
        return service.getFloorPlan(id, this.featuresToLoad, includeInventory)
    }

    // Select + Hover

    private updateSelection(updateMapSources: boolean) {
        if (this.drag || this.rotation) {
            // disable select updates during annotation drags
            return
        }
        this.dataGeoJSON.forEach((_key, features) => {
            features.forEach((feature) => {
                this._selectedIds.has((feature.id || feature.properties.id) as string)
                    ? (feature.properties.isSelected = true)
                    : delete feature.properties.isSelected
            })
        })

        if (updateMapSources) {
            this.updateBasemapSources(this.dataGeoJSON)
            this.updateMapSource(FeatureType.ANNOTATION, this.dataGeoJSON.annotation)
        }
    }

    /*
    Applies to annotations and rooms when roomHoverEnabled or wallHoverEnabled or doorHoverEnabled === true.
     */
    private updateHover(ids: string[]) {
        if (this.drag || this.rotation) {
            // disable hover updates during annotation drags
            return
        }

        const newIds = new Set(ids)
        const oldIds = this._hoveredIds
        const allIds = new Set(oldIds.values())
        ids.forEach((id) => allIds.add(id))

        let modified = false

        function updateHoverProperty(feature: Feature<Geometry, FloorPlanFeatureProperties>) {
            if (feature.id) {
                const id = feature.id.toString()
                if (allIds.has(id)) {
                    if (oldIds.has(id) && !newIds.has(id)) {
                        // remove hover property
                        delete feature.properties.isHovered
                        modified = true
                    } else if (!oldIds.has(id) && newIds.has(id)) {
                        // add hover property
                        feature.properties.isHovered = true
                        modified = true
                    }
                }
            }
        }

        this.dataGeoJSON.annotation.forEach(updateHoverProperty)
        if (this.roomHoverEnabled) this.dataGeoJSON.room.forEach(updateHoverProperty)
        if (this.wallHoverEnabled) this.dataGeoJSON.wall.forEach(updateHoverProperty)
        if (this.doorHoverEnabled) this.dataGeoJSON.door.forEach(updateHoverProperty)
        if (this.floorHoverEnabled && this.dataGeoJSON.floor.length) updateHoverProperty(this.dataGeoJSON.floor[0])

        this._hoveredIds = newIds

        if (modified) {
            this.updateMapSource(FeatureType.ANNOTATION, this.dataGeoJSON.annotation)
            if (this.roomHoverEnabled) this.updateMapSource(FeatureType.ROOM, this.dataGeoJSON.room)
            if (this.wallHoverEnabled) this.updateMapSource(FeatureType.WALL, this.dataGeoJSON.wall)
            if (this.doorHoverEnabled) this.updateMapSource(FeatureType.DOOR, this.dataGeoJSON.door)
            if (this.floorHoverEnabled && this.dataGeoJSON.floor.length)
                this.updateMapSource(FeatureType.FLOOR, this.dataGeoJSON.floor)
        }
    }

    private loadAndRegisterAnnotationImages(annotations: Annotation[]) {
        annotations.forEach((annotation) => {
            if (isPointAnnotation(annotation) || isPolygonAnnotation(annotation)) {
                for (const imageURL of [
                    annotation.properties?.style?.icon,
                    annotation.properties?.selectedStyle?.icon,
                    annotation.properties?.hoverStyle?.icon
                ]) {
                    if (imageURL && !this.registeredImageURLs.has(imageURL)) {
                        this.registeredImageURLs.add(imageURL)
                        this.map.loadImage(imageURL, (error?: any, image?: any) => {
                            if (error) {
                                console.log('Failed to load image at: ', imageURL, error)
                                this.registeredImageURLs.delete(imageURL)
                            } else if (image) {
                                this.map.addImage(fastHash(imageURL).toString(), image)
                            }
                        })
                    }
                }
            }
        })
    }

    // Utility

    private updateBasemapSources(data: SpaceData<Feature<Geometry, FloorPlanFeatureProperties>[]>) {
        const basemapFeatureTypes = [FeatureType.FLOOR, FeatureType.ROOM, FeatureType.WALL, FeatureType.DOOR]
        basemapFeatureTypes.forEach((type) => this.updateMapSource(type, data[type]))
    }

    private updateMapSource(type: FeatureType, features: Feature[]): void {
        if (!this.isStyleLoaded) return
        const source = this.map.getSource(type) as GeoJSONSource
        source?.setData({ type: 'FeatureCollection', features: features })
    }

    private zoomTo(
        floorData: SpaceData<Feature<Geometry, FloorPlanFeatureProperties>[]>,
        completion?: (camera: FloorPlanCamera) => void
    ) {
        let minLng: number = Infinity
        let maxLng: number = -Infinity
        let minLat: number = Infinity
        let maxLat: number = -Infinity
        floorData.forEach((_, features) =>
            features.forEach((feature: Feature<Geometry, FloorPlanFeatureProperties>) => {
                let coordinates: number[][]
                if (feature.geometry.type == 'Point') {
                    coordinates = [feature.geometry.coordinates]
                } else if (feature.geometry.type == 'LineString') {
                    coordinates = feature.geometry.coordinates
                } else if (feature.geometry.type == 'Polygon') {
                    coordinates = feature.geometry.coordinates[0]
                } else {
                    console.log('Unsupported geometry type for "zoomTo" method:', feature.geometry.type)
                    coordinates = []
                }
                coordinates.forEach(([lng, lat]) => {
                    if (minLng > lng) minLng = lng
                    if (maxLng < lng) maxLng = lng
                    if (minLat > lat) minLat = lat
                    if (maxLat < lat) maxLat = lat
                })
            })
        )
        if (maxLng == -Infinity || maxLat == -Infinity) return

        const bounds: LngLatBoundsLike = [
            [minLng, minLat],
            [maxLng, maxLat]
        ]
        const localCamera = this.map.cameraForBounds(bounds, { padding: this.map.getPitch() * 1.5 })
        if (localCamera) {
            if (completion) {
                this.map.once('moveend', () => completion(this.camera))
            }
            this.map.flyTo(localCamera)
        }
    }

    private getMapFeaturesAtPoint(point: Point): MapboxGeoJSONFeature[] {
        if (!this.isStyleLoaded) {
            return []
        }
        const features = this.map.queryRenderedFeatures(point)
        // add ids
        features.forEach((feature) => (feature.id = feature.properties?.id as string))
        return features
    }

    private getMapAnnotationsAtPoint(point: Point): MapboxFeature[] {
        if (!this.isStyleLoaded) return []
        const style = this.map.getStyle()
        if (!style) return []

        const annotationLayers = style
            .layers!.filter((layer) => layer.id.includes('WSAnnotation'))
            .map((layer) => layer.id)
        // const features = this.map.queryRenderedFeatures(point)
        const features = this.map.queryRenderedFeatures(point, { layers: annotationLayers })
        // add ids
        features.forEach((feature) => (feature.id = feature.properties?.id as string))
        return features as MapboxFeature[]
    }

    private createFloorPlanEvent(
        geoJSONFeatures: MapboxGeoJSONFeature[],
        lngLat: number[],
        originalEvent: MouseEvent
    ): FloorPlanEvent {
        const featureIds = new Set(geoJSONFeatures.flatMap((f) => (f.id ? [f.id.toString()] : [])))
        // TODO: Future: Optimize this. The lines below represent the slowest SDK code during annotation drags. Index features by id for quick lookup?
        const features: EventFeatures = {
            floor: this.data.floor,
            doors: this.data.doors.filter((item) => featureIds.has(item.uuid)) || [],
            portals: this.data.portals.filter((item) => featureIds.has(item.uuid)) || [],
            rooms: this.data.rooms.filter((item) => featureIds.has(item.uuid)) || [],
            walls: this.data.walls.filter((item) => featureIds.has(item.uuid)) || [],
            annotations: this.annotations.filter((item) => featureIds.has(item.id)) || []
        }

        const point = new WeSpaceProjection().project(lngLat)
        return {
            features: features,
            point: point,
            originalEvent: originalEvent
        }
    }
}

interface CustomFloorPlanStyles {
    floor?: (room: FPFloor) => PolygonAnnotationStyle | null
    room?: (room: FPRoom) => PolygonAnnotationStyle | null
    wall?: (room: FPWall) => PolygonAnnotationStyle | null
    door?: (room: FPDoor) => PolygonAnnotationStyle | null
}

function convertToGeoJSON(
    floorPlan: FloorPlanData,
    projection: Projection,
    customStyles: CustomFloorPlanStyles
): SpaceData<Feature<Geometry, FloorPlanFeatureProperties>[]> {
    const floor = floorPlan.floor
        ? [FloorPlanToGeoJsonConverter.convertFloor(floorPlan.floor, projection, customStyles.floor)]
        : []
    return new SpaceData<Feature<Geometry, FloorPlanFeatureProperties>[]>(
        [],
        [],
        FloorPlanToGeoJsonConverter.convertDoors(floorPlan.doors, projection, customStyles.door),
        floor,
        FloorPlanToGeoJsonConverter.convertObjects(floorPlan.objects, projection),
        FloorPlanToGeoJsonConverter.convertPortals(floorPlan.portals, projection),
        FloorPlanToGeoJsonConverter.convertRooms(floorPlan.rooms, projection, customStyles.room),
        FloorPlanToGeoJsonConverter.convertWalls(floorPlan.walls, projection, customStyles.wall)
    )
}
