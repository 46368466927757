import React from 'react';
import cn from 'classnames';
import { SemanticCOLORS, SemanticSIZES, SemanticTEXTALIGNMENTS } from 'semantic-ui-react';
// @ts-ignore
import { getElementType } from 'semantic-ui-react/dist/commonjs/lib';

export interface TextProps extends StrictTextProps {
  [key: string]: any;
}

export interface StrictTextProps {
  /** An element type to render as (string or function). */
  as?: any;

  /** Primary content. */
  children?: React.ReactNode;

  /** Additional classes. */
  className?: string;

  /** Text color. */
  color?: SemanticCOLORS;

  /** Show that the text is inactive. */
  disabled?: boolean;

  /** Inverts the color of the tet for dark backgrounds. */
  inverted?: boolean;

  /** Text sizes. */
  size?: SemanticSIZES;

  /** Align header content. */
  textAlign?: SemanticTEXTALIGNMENTS;

  /** A text can be formatted to show different levels of emphasis. */
  primary?: boolean;

  /** A text can be formatted to show different levels of emphasis. */
  secondary?: boolean;

  /** A text can hint towards a positive consequence. */
  positive?: boolean;

  /** A text can hint towards a negative consequence. */
  negative?: boolean;
}

export const Text: React.FC<TextProps> = (props) => {
  const {
    size,
    color,
    disabled,
    inverted,
    primary,
    secondary,
    positive,
    negative,
    textAlign,
    className,
    as,
    ...rest
  } = props;

  const classes = cn(className, 'ui', 'text', color, size, textAlign, {
    disabled,
    inverted,
    primary,
    positive,
    negative,
    secondary,
  });

  const ElementType = getElementType(Text, props);

  return <ElementType className={classes} {...rest} />;
};

Text.displayName = 'Text';

export default Text;
