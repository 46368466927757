export type Segment = [number[], number[]]

/**
 * An index of segments by their endpoints.
 */
export class LineSegmentIndex {
    bucketSize: number
    index = new Map<string, Array<[number[], number[]]>>()

    constructor(bucketSize: number, segments: Segment[]) {
        this.bucketSize = bucketSize
        this.indexSegments(segments)
    }

    /**
     * Returns segments with their first point near `coord`. The returned array may contain duplicated and reversed segments.
     * @param coord
     */
    segmentsStartingNear(coord: number[]): Segment[] {
        let near: Segment[] = []
        for (const x of [coord[0] - this.bucketSize, coord[0], coord[0] + this.bucketSize]) {
            for (const y of [coord[1] - this.bucketSize, coord[1], coord[1] + this.bucketSize]) {
                const segments = this.index.get(this.keyForCoord([x, y]))
                if (segments?.length) {
                    near = near.concat(segments)
                }
            }
        }
        return near
    }

    private keyForCoord(coord: number[]): string {
        return Math.floor(coord[0] / this.bucketSize).toString() + Math.floor(coord[1] / this.bucketSize).toString()
    }

    private indexSegments(segments: Segment[]) {
        segments.forEach((segment) => {
            ;[segment, segment.reverse() as Segment].forEach((segment) => {
                const key = this.keyForCoord(segment[0])
                const bucket = this.index.get(key)
                if (bucket) {
                    bucket.push(segment)
                } else {
                    this.index.set(key, [segment])
                }
            })
        })
    }
}
