import React, { ChangeEvent, FocusEvent, useCallback, useState } from 'react';
import cx from 'classnames';
import {
  FormField as SemanticFormField,
  StrictFormFieldProps as SemanticStrictFormFieldProps,
} from 'semantic-ui-react';

import Grid from '../Grid/Grid';
import { ForwardRefComponent } from '../../types';

type PrimitiveType = boolean | number | string;

export type FormFieldValue = PrimitiveType | Array<PrimitiveType>;

export interface StrictFormFieldProps extends SemanticStrictFormFieldProps {
  defaultValue?: FormFieldValue;
  hint?: React.ReactChild;
  maxLength?: number;
  value?: FormFieldValue;
  onChange?: <T = Element>(event: ChangeEvent<T>, changeProps?: FormFieldProps) => void;
  iconPosition?: 'left';
  onFocus?: React.FocusEventHandler;
  onBlur?: React.FocusEventHandler;
  /** A field can have its label covering it and moving above it when focused. */
  outline?: boolean;
  /** Float label above field, regardless of it having a value; Only relevant when outline is true */
  floatLabel?: boolean;
}

export interface FormFieldProps extends StrictFormFieldProps {
  [key: string]: any;
}

interface State {
  focused: boolean;
  pristine: boolean;
  value: PrimitiveType | Array<PrimitiveType>;
}

const hasValueProp = (props: FormFieldProps): boolean => {
  // eslint-disable-next-line no-prototype-builtins
  return props.hasOwnProperty('value');
};

const canCalculateLength = (value: FormFieldValue): value is string => {
  return typeof value === 'string';
};

const getDefaultValue = (multiple = false): string | [] => {
  return multiple ? [] : '';
};

export const FormField: ForwardRefComponent<StrictFormFieldProps, HTMLDivElement> = React.forwardRef((props, ref) => {
  const {
    outline,
    floatLabel,
    className,
    hint,
    error,
    maxLength,
    value: propsValue,
    defaultValue,
    onChange,
    onFocus,
    onBlur,
    ...rest
  } = props;
  const [focused, setFocused] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [stateValue, setStateValue] = useState<FormFieldValue>(defaultValue ?? getDefaultValue(props.multiple));

  const isControlledComponent = hasValueProp(props);
  const value = isControlledComponent ? (propsValue as FormFieldValue) : stateValue;

  const handleChange = useCallback(
    (evt: ChangeEvent<HTMLElement & { value: string }>, changeProps?: FormFieldProps) => {
      const { value: propsChangeValue } = changeProps || {};

      const changeValue =
        changeProps && hasValueProp(changeProps) ? (propsChangeValue as FormFieldValue) : evt.currentTarget.value;

      if (maxLength && canCalculateLength(changeValue) && changeValue.length > maxLength) {
        return;
      }

      setPristine(false);

      if (!isControlledComponent) {
        setStateValue(changeValue);
      }

      if (onChange) {
        onChange(evt, changeProps);
      }
    },
    [maxLength, onChange, isControlledComponent],
  );

  const handleFocus = useCallback(
    (evt: FocusEvent) => {
      setFocused(true);

      if (onFocus) {
        onFocus(evt);
      }
    },
    [onFocus],
  );

  const handleBlur = useCallback(
    (evt: FocusEvent) => {
      setFocused(false);

      if (onBlur) {
        onBlur(evt);
      }
    },
    [onBlur],
  );

  const classes = cx({
    outline: !!outline,
    focused: !!focused,
    hinted: !!hint,
    filled: floatLabel || (pristine && !!props.defaultValue) || !!value,
    icon: !!props.icon,
    left: props.iconPosition === 'left',
  });

  return (
    <div ref={ref} className={cx('wrapper', className)}>
      <SemanticFormField
        {...rest}
        value={value}
        className={classes}
        error={error}
        maxLength={maxLength}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {hint || (maxLength && canCalculateLength(value)) ? (
        <div className={cx('hint', error && 'error')}>
          <Grid columns="equal">
            <Grid.Column>{hint}</Grid.Column>
            {maxLength && canCalculateLength(value) ? (
              <Grid.Column width={1} textAlign="right" floated="right">{`${value.length}/${maxLength}`}</Grid.Column>
            ) : null}
          </Grid>
        </div>
      ) : null}
    </div>
  );
});

export default FormField;
