import React from 'react';
import cx from 'classnames';
import {
  Divider as SemanticDivider,
  DividerProps as SemanticDividerProps,
  StrictDividerProps as SemanticStrictDividerProps,
} from 'semantic-ui-react';

export interface StrictDividerProps extends SemanticStrictDividerProps {
  /** Lighter version of divider. */
  light?: boolean;
  /** Dashed divider. */
  dashed?: boolean;
}

export interface DividerProps extends SemanticDividerProps, StrictDividerProps {}

export function Divider({ light, dashed, className, ...props }: DividerProps) {
  const classes = cx(className, { light, dashed });

  return <SemanticDivider className={classes} {...props} />;
}

export default Divider;
