import { LineSegmentIndex, Segment } from './LineSegmentIndex'
import { Feature, Polygon } from 'geojson'
import dissolve from '@turf/dissolve'
import { distance, translateCoord } from './helpers'

// The distance between
const maxSnapToleranceDegrees = 0.000005
const indexDistanceDegrees = 0.00001

export type Transform = {
    translation: number[]
    rotation: number
    rotationCenter: number[]
}

export class PolygonSnapManager {
    segmentIndex = new LineSegmentIndex(indexDistanceDegrees, [])

    snapPolygons(polygons: Feature<Polygon>[], offset: number[] = [0, 0]): Transform | null {
        let boundary
        if (polygons.length > 1) {
            const dissolvedFeatureCollection = dissolve({
                type: 'FeatureCollection',
                features: polygons.filter((polygon) => polygon?.geometry?.type === 'Polygon')
            })
            boundary = dissolvedFeatureCollection.features[0]?.geometry?.coordinates[0]
        } else {
            boundary = polygons[0]?.geometry?.coordinates[0]
        }

        for (let i = 0; i < boundary.length; i++) {
            const start = boundary[i]
            const end = boundary[i + 1] || boundary[0]
            const startTranslated = translateCoord(start, offset)
            const endTranslated = translateCoord(end, offset)
            const near = this.segmentIndex.segmentsStartingNear(startTranslated)
            for (const segment of near) {
                const tolerance = Math.min(distance(startTranslated, endTranslated) * 0.51, maxSnapToleranceDegrees)
                if (
                    distance(startTranslated, segment[0]) < tolerance &&
                    distance(endTranslated, segment[1]) < tolerance
                ) {
                    const translation = [segment[0][0] - start[0], segment[0][1] - start[1]]
                    const polygonSegmentAngle = Math.atan2(end[1] - start[1], end[0] - start[0])
                    const snapSegmentAngle = Math.atan2(segment[1][1] - segment[0][1], segment[1][0] - segment[0][0])
                    const rotation = polygonSegmentAngle - snapSegmentAngle
                    return { translation: translation, rotation: rotation, rotationCenter: start }
                }
            }
        }
        return null
    }

    /**
     * An expensive operation.
     * @param features - A list of geoJSON features.
     * Each line segment in each polygon will be indexed forward and backward.
     * Non-polygon features are ignored.
     */
    generateSnapIndex(features: Feature[]) {
        const segments: Segment[] = []
        features.forEach((feature) => {
            if (feature.geometry.type != 'Polygon') return
            const polygon = feature as Feature<Polygon>
            const boundary = polygon.geometry.coordinates[0]
            if (boundary.length <= 1) return
            for (let i = 0; i < boundary.length; i++) {
                const start = boundary[i]
                const end = boundary[i + 1] || boundary[0]
                if (start[0] == end[0] && start[1] == end[1]) return
                segments.push([start, end])
            }
        })
        this.segmentIndex = new LineSegmentIndex(indexDistanceDegrees, segments)
    }
}
