import { FPFloor, FPRoom, FPObject, FPDoor, FPWall, FPPortal } from './FloorPlanData.public.interfaces'
import axios from 'axios'
import { FloorPlanEnvironment } from '../floorPlan/FloorPlan.public.interfaces'
import { cleanupObjects } from './dataCleanup'

export class FloorPlanData {
    floor: FPFloor | null
    rooms: FPRoom[]
    objects: FPObject[]
    doors: FPDoor[]
    walls: FPWall[]
    portals: FPPortal[]

    constructor(
        floor: FPFloor | null = null,
        rooms: FPRoom[] = [],
        objects: FPObject[] = [],
        doors: FPDoor[] = [],
        walls: FPWall[] = [],
        portals: FPPortal[] = []
    ) {
        this.floor = floor
        this.rooms = rooms
        this.objects = objects
        this.doors = doors
        this.walls = walls
        this.portals = portals
    }
}

export class FloorFeatures {
    static FLOOR = 0
    static ROOMS = 1
    static OBJECTS = 2
    static DOORS = 3
    static WALLS = 4
    static PORTALS = 5

    static default: FloorFeatures[] = [
        FloorFeatures.FLOOR,
        FloorFeatures.ROOMS,
        FloorFeatures.DOORS,
        FloorFeatures.WALLS
    ]
}

/*
Loads data from SRS via web-grpc
 */
export class FloorPlanDataService {
    environment: FloorPlanEnvironment
    auth0TokenProvider: () => Promise<string>
    latestHarvest: boolean

    constructor(
        environment: FloorPlanEnvironment,
        auth0TokenProvider: () => Promise<string>,
        loadUnapprovedLatestHarvestData: boolean = false
    ) {
        this.environment = environment
        this.auth0TokenProvider = auth0TokenProvider
        this.latestHarvest = loadUnapprovedLatestHarvestData
    }

    getFloorPlan(
        id: string,
        children: FloorFeatures[] = FloorFeatures.default,
        includeInventory = false
    ): Promise<FloorPlanData> {
        const endpoint = this.latestHarvest ? '/latestHarvest/floorplan/' : '/floorplan/'
        let url = this.environment + endpoint + id
        if (includeInventory) url = url + '?includeInventory=true'
        return this.auth0TokenProvider().then((token) => {
            const headers = { Authorization: 'Bearer ' + token }
            return axios
                .get(url, { headers: headers })
                .then((r) => {
                    if (r.status === 200 && r.data) return r.data as FloorPlanData
                    else return Promise.reject('Status: ' + String(r.status) + ' ' + String(r.data))
                })
                .catch((e) => Promise.reject('Failed to load floor plan:' + String(e)))
                .then((response) => {
                    return new FloorPlanData(
                        children.includes(FloorFeatures.FLOOR) ? response.floor || null : null,
                        children.includes(FloorFeatures.ROOMS) ? response.rooms || [] : [],
                        children.includes(FloorFeatures.OBJECTS) ? cleanupObjects(response.objects || []) : [],
                        children.includes(FloorFeatures.DOORS) ? response.doors || [] : [],
                        children.includes(FloorFeatures.WALLS) ? response.walls || [] : [],
                        children.includes(FloorFeatures.PORTALS) ? response.portals || [] : []
                    )
                })
        })
    }
}
