export interface SimpleCacheMap<T> {
  [key: string]: T;
}

export type SimpleCacheType<T> = {
  getCachedEntries: (keys: string[]) => SimpleCacheMap<T>;
  getCachedEntry: (key: string) => T | undefined;
  setCachedEntries: (cacheMap: SimpleCacheMap<T>) => void;
  reset: () => void;
  getMissingKeys: (keys: string[]) => string[];
};

export function makeSimpleCache<T>(): SimpleCacheType<T> {
  const cache = {};
  const getCachedEntries = (keys: string[]): SimpleCacheMap<T> =>
    keys.reduce((acc, key) => {
      acc[key] = cache[key];
      return acc;
    }, {});
  const getCachedEntry = (key: string): T | undefined => cache[key];

  const setCachedEntries = (cacheMap: SimpleCacheMap<T>): void => {
    Object.entries(cacheMap).forEach(([key, val]) => {
      cache[key] = val;
    });
  };

  const reset = (): void => {
    Object.keys(cache).forEach((key) => delete cache[key]);
  };

  const getMissingKeys = (keys: string[]): string[] => keys.filter((key) => cache[key] === undefined);

  return {
    getCachedEntries,
    getCachedEntry,
    setCachedEntries,
    getMissingKeys,
    reset,
  };
}
