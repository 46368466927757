import has from 'lodash.has';

import ar from './ar.json';
import csCZ from './cs-CZ.json';
import deDE from './de-DE.json';
import enUS from './en-US.json';
import esES from './es-ES.json';
import esLA from './es-LA.json';
import frCA from './fr-CA.json';
import frFR from './fr-FR.json';
import idID from './id-ID.json';
import itIT from './it-IT.json';
import jaJP from './ja-JP.json';
import koKR from './ko-KR.json';
import nlNL from './nl-NL.json';
import plPL from './pl-PL.json';
import ptBR from './pt-BR.json';
import ruRU from './ru-RU.json';
import thTH from './th-TH.json';
import viVN from './vi-VN.json';
import zhCN from './zh-CN.json';
import zhTW from './zh-TW.json';

interface ITranslation {
  errors: {
    [key: string]: {
      title: string;
      description: string;
    };
  };
}

const wrapTranslations = (translation: ITranslation): { translation: ITranslation } => ({ translation });

export const containsErrorKey = (errorKey: string): boolean => has(enUS, `errors.${errorKey}`);

/*
  translation object structure:
  {
    'en-US': {
      translation: {
        'translatedKey': 'translatedValue,
      }
    }
  }
 */
export default {
  ar: wrapTranslations(ar),
  cs: wrapTranslations(csCZ),
  'cs-CZ': wrapTranslations(csCZ),
  de: wrapTranslations(deDE),
  'de-DE': wrapTranslations(deDE),
  en: wrapTranslations(enUS),
  'en-US': wrapTranslations(enUS),
  es: wrapTranslations(esLA),
  'es-ES': wrapTranslations(esES),
  'es-LA': wrapTranslations(esLA),
  fr: wrapTranslations(frFR),
  'fr-CA': wrapTranslations(frCA),
  'fr-FR': wrapTranslations(frFR),
  id: wrapTranslations(idID),
  'id-ID': wrapTranslations(idID),
  it: wrapTranslations(itIT),
  'it-IT': wrapTranslations(itIT),
  ja: wrapTranslations(jaJP),
  'ja-JP': wrapTranslations(jaJP),
  ko: wrapTranslations(koKR),
  'ko-KR': wrapTranslations(koKR),
  nl: wrapTranslations(nlNL),
  'nl-NL': wrapTranslations(nlNL),
  pl: wrapTranslations(plPL),
  'pl-PL': wrapTranslations(plPL),
  pt: wrapTranslations(ptBR),
  'pt-BR': wrapTranslations(ptBR),
  ru: wrapTranslations(ruRU),
  'ru-RU': wrapTranslations(ruRU),
  th: wrapTranslations(thTH),
  'th-TH': wrapTranslations(thTH),
  vi: wrapTranslations(viVN),
  'vi-VN': wrapTranslations(viVN),
  zh: wrapTranslations(zhCN),
  'zh-CN': wrapTranslations(zhCN),
  'zh-TW': wrapTranslations(zhTW),
};
